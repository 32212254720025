<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Alquiler de Aulas</h4>
          </div>
          <!--mensajes de alerta al crear eliminar o modificar registros-->
          <div
            class="card-header"
            v-if="success.length > 0 || Object.keys(this.error).length > 0"
          >
            <div class="alert alert-success" v-if="success.length > 0">
              <p class="m-auto">{{ success }}</p>
            </div>

            <div
              class="alert alert-danger"
              v-if="Object.keys(this.error).length > 0"
            >
              <div v-for="mensaje in error" :key="mensaje">
                {{ mensaje[0] }}
              </div>
            </div>
          </div>
          <!--contenido tabla crud-->
          <div class="card-body">
            <div class="table-full-width">
              <table
                class="table display wrap"
                style="width: 100%"
                cellspacing="0"
                id="arrendatariosAulasDatatable"
              >
                <thead class="text-primary">
                  <tr>
                    <th class="text-center">Aula</th>
                    <th class="text-center">Documento de Identidad</th>
                    <th class="text-center">Nombre</th>
                    <th class="text-center">Apellidos</th>
                    <th class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <!--recorremos el array que contiene todos los registros que hemos obtenido desde el controlador-->
                  <tr
                    v-for="arrendatario_aula in arrendatariosPorAula"
                    :id="arrendatario_aula.id"
                    :key="arrendatario_aula.id"
                  >
                    <!--mostramos los apartados de cada registro de la tabla-->
                    <td class="text-center">
                      {{ arrendatario_aula.nombre_aulas }}
                    </td>
                    <td class="text-center">
                      {{ arrendatario_aula.documento_identidad }}
                    </td>
                    <td class="text-center">
                      {{ arrendatario_aula.nombre }}
                    </td>
                    <td class="text-center">
                      {{ arrendatario_aula.apellidos }}
                    </td>
                    <td class="text-center">
                      <button
                        title="editar"
                        type="button"
                        class="btn btn-success btn-sm btn-round btn-icon"
                        v-on:click="
                          $refs.editarArrendatarioAula.showForm(
                            arrendatario_aula
                          )
                        "
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </button>

                      <!--utilizamos la directiva v-on:click para realizar la accion de llamar a la funcion borrar-->
                      <button
                        type="button"
                        title="eliminar"
                        class="btn btn-danger btn-sm btn-round btn-icon"
                        v-on:click="deleteArrendatariosAula(arrendatario_aula)"
                      >
                        <i class="tim-icons icon-trash-simple"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!--Componentes hijos agregados llamando al metodo mostrarNotificacion que tiene el padre-->
              <!-- :listado_.... es una propiedad que nos permite acceder al array cursos-->
              <ArrendatariosPorAulaCrear
                :listado_arrendatarios="arrendatarios"
                :listado_aulas="aulas"
                @mostrarNotificacion="mostrarNotificacion"
              ></ArrendatariosPorAulaCrear>
              <ArrendatariosPorAulaEditar
                :listado_aulas="aulas"
                @mostrarNotificacion="mostrarNotificacion"
                ref="editarArrendatarioAula"
              ></ArrendatariosPorAulaEditar>
            </div>
          </div>
        </div>
        <!--mostramos el formulario de crear del componente hijo-->
        <button
          data-bs-toggle="modal"
          data-bs-target="#crearArrendatarioAula"
          type="button"
          class="btn btn-info"
        >
          Agregar Arrendatario
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  //cuando se carga el componente obtiene los registros de la tabla desde el controlador
  created: function () {
    this.getArrendatarioAula(); //accedemos al metodo index del controlador que devuelve los regitros de la tabla
  },
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      arrendatarios: [],
      aulas: [],
      arrendatariosPorAula: [], //Array que utilizaremos para almacenar los registros que haya creados en la bd.
      success: "", //variable que utilizaremos para almacenar la respuesta satisfactoria del controlador
      error: "", //variable que utilizaremos para almacenar los errores del controlador
      select: {
        fila_editada: 0,
      },
    };
  },
  //funcion para definir los diferentes metodos que se utilizaran a lo largo del componente
  methods: {
    //funcion para obtener los registros que hay en la base de datos
    getArrendatarioAula() {
      // Para poder actualizar correctamente el datatable.
      $("#arrendatariosAulasDatatable").DataTable().destroy();

      let arrendatariosAulaIndex = "arrendatarioPorAula"; //almaceno en la variable la ruta que accede al metodo index del controlador

      axios
        .get(arrendatariosAulaIndex)
        .then((response) => {
          //recoger la respuesta del controlador
          this.arrendatariosPorAula = response.data.arrendatarios_aulas; //lo almacena en el array
          this.arrendatarios = response.data.arrendatarios;
          this.aulas = response.data.aulas;
        })
        .finally(() => {
          $("#arrendatariosAulasDatatable").DataTable({
            //cambio de idioma datatables a español
            language: {
              url: "datatables-es.txt",

              buttons: {
                selectAll: "Seleccionar Todo",
                selectNone: "Deseleccionar Todo",
              },
            },
            //activamos la funcion select para seleccionar filas
            select: true,

            //desactiva el filtrado de la tabla al cargar la vista
            aaSorting: [],

            //datatables responsive
            responsive: true,
            //prioridad de visibilidad en el responsive la columna más a la izquierda ( target:0) se le asigna una prioridad de 1,
            // mientras que a la columna más a la derecha ( target:-1) se le asigna una prioridad de 2.
            columnDefs: [
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 },
            ],

            //cuando la tabla esta cargada se dirige al último registro editado
            initComplete: (settings, json) => {
              if (this.select.fila_editada != -1) {
                $("#arrendatariosAulasDatatable")
                  .DataTable()
                  .row("tr#" + this.select.fila_editada)
                  .select()
                  .show()
                  .draw(false);
              }
            },

            // Funcionalidades de los botones que se mostrarán en la datatable.
            dom: "QBfrltip",
            destroy: true,
            //configuracion de botones que apareceran como opciones
            buttons: [
              "selectAll",
              "selectNone",
              {
                extend: "copyHtml5",
                exportOptions: {
                  columns: "th:not(:nth-child(5))", // Ocultar la columna 5 (Acciones) al exportar.
                },
              },
              {
                extend: "excelHtml5",
                title: "Tamonante Educa - Profesores que imparten Cursos",
                exportOptions: {
                  columns: "th:not(:nth-child(5))", // Ocultar la columna 5 (Acciones) al exportar.
                },
              },
              {
                extend: "pdfHtml5",
                title: "Tamonante Educa - Profesores que imparten Cursos",
                exportOptions: {
                  columns: "th:not(:nth-child(5))", // Ocultar la columna 5 (Acciones) al exportar.
                },
              },

              {
                extend: "print",
                title: "Tamonante Educa - Profesores que imparten Cursos",
                exportOptions: {
                  columns: "th:not(:nth-child(5))", // Ocultar la columna 5 (Acciones) al exportar.
                },
              },
              {
                // boton para elegir las columnas que se quieren mostrar en la tabla.
                extend: "colvis",
              },
            ],

            // Especificar las columnas disponibles para filtrar.
            searchBuilder: {
              columns: [0, 1, 2, 3],
            },
          });
        });
    },
    //funcion para eliminar un registro de la bd
    deleteArrendatariosAula(arrendatario_aula) {
      if (confirm("¿Desea eliminar este registro?")) {
        //resetear variables
        this.success = "";
        this.error = "";
        // le pasamos a la funcion el objeto del array del v-for
        let arrendatarioAulaDelete =
          " arrendatarioPorAula/" + arrendatario_aula.id; //almacenamos en la variable la ruta hacia el controlador + id que accede al metodo delete del controlador
        axios
          .delete(arrendatarioAulaDelete)
          .then((response) => {
            if (response.status === 200) {
              this.success = response.data.mensaje;
              this.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.error = error.response.data.mensaje;
              this.success = "";
            }
          })

          .finally(() => {
            this.getArrendatarioAula(); // Refrescamos la lista de la tabla con la funcion creada anteriomente.
          });
      }
    },

    //funcion que muestra un mensaje sastisfactorio o de errores de las operaciones que se lleven a cabo  a través dela variable "resultado" en los componentes hijos
    mostrarNotificacion(resultado) {
      this.getArrendatarioAula(); //refresca la tabla
      this.select.fila_editada = resultado.select.fila_editada;

      this.success = resultado.success;
      this.error = resultado.error;
    },
  },
};
</script>

