<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el resgitro-->
  <div class="modal" id="editarArrendatarioAula">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="editarArrendatarioAulaLabel">
            Editar Alquiler
          </h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <!--  mostramos información del alumno seleccionado -->
          <fieldset v-if="arrendatario_info !== 'default'">
            <!--condicion para mostrar información solo cuando se seleccione-->
            <legend>Información del Arrendatario</legend>
            <p class="text-danger font-weight-bold">
              Nombre:
              <label
                for="nombreArrendatario"
                class="text-dark font-weight-bold"
              >
                {{ arrendatario_info.nombre }}
              </label>
            </p>
            <p class="text-danger font-weight-bold">
              Apellidos:
              <label
                for="apellidosArrendatario"
                class="text-dark font-weight-bold"
              >
                {{ arrendatario_info.apellidos }}</label
              >
            </p>
            <p for="arrendatarioDNI" class="text-danger font-weight-bold">
              DNI:
              <label for="arrendatarioDNI" class="text-dark font-weight-bold">
                {{ arrendatario_info.documento_identidad }}</label
              >
            </p>
          </fieldset>
          <!--Aulas-->
          <label for="aula" class="text-dark font-weight-bold">Aula</label>
          <!--select para mostrar los cursos ya creados-->
          <select
            name="aula"
            class="form-control text-dark"
            v-model="aula_info"
            :class="validar_datos.aula_info.input_class"
          >
            <option v-for="aula in listado_aulas" :key="aula" :value="aula.id">
              {{ aula.nombre }}
            </option>
          </select>

          <div
            :class="validar_datos.aula_info.div_class"
            v-html="validar_datos.aula_info.msg"
          ></div>
        </div>
        <!-- v-on:click asigna un evento que al pulsar llame a la funcion actualizar-->
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="editarArrendatarioAula"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="actualizarArrendatariosAula()"
          >
            Guardar cambios
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#editarArrendatarioAula").modal({
    show: false,
    backdrop: "static",
  });
});

export default {
  //propiedades donde tenemos acceso a las variables del componente padre
  props: ["listado_aulas"],
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      arrendatario_info: "", // Guardamos los datos del alumno seleccionado en el select.
      aula_info: "", // Guardamos SOLO el ID del curso seleccionado en el select.
      arrendatario_aula_id: "",
      editaArrendatarioAula: {},
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        aula_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    // Rellenamos los datos del formulario con el registro que queremos editar visualizandolo con el showForm desde el padre
    showForm(arrendatario_aula) {
      console.log(arrendatario_aula);
      this.arrendatario_info = arrendatario_aula;
      this.aula_info = arrendatario_aula.aulas_id;
      this.arrendatario_aula_id = arrendatario_aula.id;
      this.validarDatos();
      $("#editarArrendatarioAula").modal("show");
    },

    //funcion para editar registros en la base de datos
    actualizarArrendatariosAula() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        // Almacena en el objeto actualizarAlumnoCurso la información para actualizarla en la base de datos.
        this.editaArrendatarioAula = {
          arrendatario_documento_identidad:
            this.arrendatario_info.documento_identidad,
          aulas_id: this.aula_info,
        };

        let arrendatariosAulaUpdate =
          "arrendatarioPorAula/" + this.arrendatario_aula_id; //almaceno en la variable la ruta que accede al metodo update del controlador
        axios
          .patch(arrendatariosAulaUpdate, this.editaArrendatarioAula)
          .then((response) => {
            this.resultado.select.fila_editada = this.arrendatario_aula_id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })

          //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
          .finally(() => {
            $("#editarArrendatarioAula").modal("hide");
            this.enviarNotificacion();
            this.bloquear_boton = false;
          });
      }
    },
    validarDatos() {
      let datosValidos = false;

      // AULA (OBLIGATORIO)

      if (this.aula_info === "default") {
        this.validar_datos.aula_info.valid = false;
        this.validar_datos.aula_info.msg =
          "Debe seleccionarse una <strong>Aula</strong>.";
        this.validar_datos.aula_info.input_class = "is-invalid";
        this.validar_datos.aula_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.aula_info.valid = true;
        this.validar_datos.aula_info.msg =
          "El <strong>Aula</strong> es válido.";
        this.validar_datos.aula_info.input_class = "is-valid";
        this.validar_datos.aula_info.div_class = "valid-feedback";
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (this.validar_datos.aula_info.valid) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },
  },
};
</script>