<template>
  <div class="modal" id="editarPagoRealizado">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="editarPagoRealizadoLabel">
            Editar operación del pago {{ id_pago }}
          </h3>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <div id="formularioRealizarPago">
            <label for="importe" class="text-dark font-weight-bold"
              >Importe</label
            >

            <input
              type="text"
              class="form-control text-dark"
              name="importe"
              v-model="importe_entregado"
              :class="validar_datos.importe_entregado.input_class"
            />

            <div
              :class="validar_datos.importe_entregado.div_class"
              v-html="validar_datos.importe_entregado.msg"
            ></div>

            <label for="fecha" class="text-dark font-weight-bold">Fecha</label>

            <!--Utilización de vue datepicker -->
            <datepicker
              v-model:value="fecha"
              v-on:clear="
                fecha = '';
                validarDatos();
              "
              v-on:close="validarDatos()"
              :editable="true"
              :input-class="validar_datos.fecha.input_class"
              :format="'DD-MM-YYYY'"
              :value-type="'format'"
              :lang="'es.es'"
            ></datepicker>

            <div
              :class="validar_datos.fecha.div_class"
              v-html="validar_datos.fecha.msg"
            ></div>

            <label for="metodo_pago" class="text-dark font-weight-bold"
              >Método de pago</label
            >

            <select
              id="metodoPago"
              class="form-control text-dark"
              v-model="metodo_pago"
            >
              <option value="default">Seleccionar método de pago</option>
              <option
                v-for="metodo in listado_metodos_pago"
                v-bind:key="metodo"
                :value="metodo"
              >
                {{ metodo }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="editarPagoRealizado"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="updatePagoRealizado()"
          >
            Guardar cambios
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
$(document).ready(function () {
  // Evita que el modal se cierre cuando hagamos click fuera de él.
  $("#editarPagoRealizado").modal({
    show: false,
    backdrop: "static",
  });
});

import moment from "moment";

export default {
  props: ["listado_metodos_pago"],
  data: function () {
    return {
      // Datos del formulario.
      arrendatario_info: "default", // Guardamos los datos del arrendatario seleccionado en el select.
      id: "", // Referente al campo id de la tabla pagos_realizados.
      id_pago: "", // Referente al campo id de la tabla pago_alquiler.
      importe_entregado: "",
      fecha: "",
      metodo_pago: "default",
      editarPagoRealizado: {}, // Objeto con todos los datos actualizados de la transacción para actualizar en la bd.
      resultado: {
        // Almacenar el resultado de las operaciones entre el cliente - servidor.
        success: "",
        error: [],
        select: {
          fila_editada: 0, // ID del row que ha sido editada para poderla resaltar.
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        importe_entregado: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        fecha: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "form-control",
        },
      },
    };
  },
  methods: {
    showForm(pagoRealizado) {
      // Cargamos los datos de la transacción en el formulario.

      this.id = pagoRealizado.id_transaccion;
      this.id_pago = pagoRealizado.id_pago;

      this.arrendatario_info = {
        nombre: pagoRealizado.nombre,
        apellidos: pagoRealizado.apellidos,
        documento_identidad: pagoRealizado.documento_identidad,
      };

      this.importe_entregado = pagoRealizado.importe_entregado;
      this.fecha = pagoRealizado.fecha;
      this.metodo_pago = pagoRealizado.metodo_pago;

      // Si no se ha seleccionado ningún pago, dejamos el valor por defecto en el select.

      if (this.metodo_pago === null) {
        this.metodo_pago = "default";
      }

      this.validarDatos();

      $("#editarPagoRealizado").modal("show");
    },
    updatePagoRealizado() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;
        // Actualizar los datos en la bd.

        this.editarPagoRealizado = {
          importe_entregado: this.importe_entregado,
          fecha: this.fecha,
          metodo_pago: this.metodo_pago,
          pago_alquiler_id: this.id_pago,
        };

        /* 
          Comprobar si se ha escogido o no un método de pago en el select.

          Esta comprobación se realiza ya que el campo 'metodo_pago' puede ser nulo, y en ese caso,
          tenemos que establecer a null el valor.

          Si no lo hacemos, recibiremos un error de mysql ya que 'default' (opción seleccionada por defecto) no existe
          en la lista de enum.
        
        */

        if (this.editarPagoRealizado.metodo_pago === "default") {
          this.editarPagoRealizado.metodo_pago = null;
        }

        let pagoRealizadoUpdate = "pagosRealizadosAlquiler/" + this.id;
        axios
          .patch(pagoRealizadoUpdate, this.editarPagoRealizado)
          .then((response) => {
            this.resultado.select.fila_editada = this.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            this.resultado.error = error.response.data.mensaje;
            this.resultado.success = "";
          })
          .finally(() => {
            $("#editarPagoRealizado").modal("hide");
            this.enviarNotificacion();
            this.bloquear_boton = false;
          });
      }
    },
    validarDatos() {
      let datosValidos = false;

      // IMPORTE ENTREGADO

      if (this.importe_entregado != null) {
        if (this.importe_entregado.toString().length > 0) {
          // Usamos toString para convertir el valor numérico a String y poder usar el método length.
          // Comprobar formato regex. Se permite el uso exclusivo de números y punto.
          if (/^[0-9]+(\.[0-9]+)?$/.test(this.importe_entregado)) {
            // Comprobar rango.
            if (
              this.importe_entregado >= -9999.99 &&
              this.importe_entregado <= 9999.99
            ) {
              this.validar_datos.importe_entregado.valid = true;
              this.validar_datos.importe_entregado.msg =
                "El <strong>Importe</strong> es válido.";
              this.validar_datos.importe_entregado.input_class = "is-valid";
              this.validar_datos.importe_entregado.div_class = "valid-feedback";
            } else {
              this.validar_datos.importe_entregado.valid = false;
              this.validar_datos.importe_entregado.msg =
                "Debe escribirse un <strong>Importe</strong> dentro de un rango válido.";
              this.validar_datos.importe_entregado.input_class = "is-invalid";
              this.validar_datos.importe_entregado.div_class =
                "invalid-feedback";
            }
          } else {
            this.validar_datos.importe_entregado.valid = false;
            this.validar_datos.importe_entregado.msg =
              "Debe escribirse un <strong>Importe</strong> válido.";
            this.validar_datos.importe_entregado.input_class = "is-invalid";
            this.validar_datos.importe_entregado.div_class = "invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.importe_entregado = "";
          this.validar_datos.importe_entregado.valid = true;
          this.validar_datos.importe_entregado.msg = "";
          this.validar_datos.importe_entregado.input_class = "";
          this.validar_datos.importe_entregado.div_class = "";
        }
      } else {
        // En caso de ser nulo, retirar clases de estilo para validar.
        this.validar_datos.importe_entregado.valid = true;
        this.validar_datos.importe_entregado.msg = "";
        this.validar_datos.importe_entregado.input_class = "";
        this.validar_datos.importe_entregado.div_class = "";
      }

      // FECHA

      if (this.fecha !== null) {
        if (this.fecha.length > 0) {
          if (moment(this.fecha, "DD-MM-YYYY", true).isValid()) {
            this.validar_datos.fecha.valid = true;
            this.validar_datos.fecha.msg =
              "La <strong>Fecha</strong> es válida.";
            this.validar_datos.fecha.input_class =
              "form-control text-dark is-valid";
            this.validar_datos.fecha.div_class = "d-block valid-feedback";
          } else {
            this.validar_datos.fecha.valid = false;
            this.validar_datos.fecha.msg =
              "La <strong>Fecha</strong> no es válida.";
            this.validar_datos.fecha.input_class =
              "form-control text-dark is-invalid";
            this.validar_datos.fecha.div_class = "d-block invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.fecha = "";
          this.validar_datos.fecha.valid = true;
          this.validar_datos.fecha.msg = "";
          this.validar_datos.fecha.input_class = "";
          this.validar_datos.fecha.div_class = "";
        }
      } else {
        // En caso de ser nulo, retirar clases de estilo para validar.
        this.validar_datos.fecha.valid = true;
        this.validar_datos.fecha.msg = "";
        this.validar_datos.fecha.input_class = "";
        this.validar_datos.fecha.div_class = "";
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.importe_entregado.valid &&
        this.validar_datos.fecha.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },
  },
};
</script>