<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el resgitro-->
  <div class="modal" id="crearArrendatarioAula">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="crearArrendatarioAulaLabel">
            Añadir Alquiler
          </h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <!--  mostramos información del alumno seleccionado -->
          <fieldset v-if="arrendatario_info !== 'default'">
            <!--condicion para mostrar información solo cuando se seleccione-->
            <legend>Información del Arrendatario</legend>
            <p class="text-danger font-weight-bold">
              Nombre:
              <label
                for="nombreArrendatario"
                class="text-dark font-weight-bold"
                >{{ arrendatario_info.nombre }}</label
              >
            </p>
            <p class="text-danger font-weight-bold">
              Apellidos:
              <label
                for="apellidosArrendatario"
                class="text-dark font-weight-bold"
              >
                {{ arrendatario_info.apellidos }}</label
              >
            </p>
            <p for="arrendatarioDNI" class="text-danger font-weight-bold">
              DNI:
              <label for="dniArrendatario" class="text-dark font-weight-bold">
                {{ arrendatario_info.documento_identidad }}</label
              >
            </p>
          </fieldset>
          <!--Aulas-->
          <label for="aula" class="text-dark font-weight-bold">Aula</label>
          <!--select para mostrar los cursos ya creados-->
          <select
            name="aula"
            class="form-control text-dark"
            v-model="aula_info"
            :class="validar_datos.aula_info.input_class"
          >
            <option value="default" selected>Seleccionar un Aula</option>
            <option v-for="aula in listado_aulas" :key="aula" :value="aula.id">
              {{ aula.nombre }}
            </option>
          </select>

          <div
            :class="validar_datos.aula_info.div_class"
            v-html="validar_datos.aula_info.msg"
          ></div>

          <!--arrendatarios-->
          <label for="arrendatario" class="text-dark font-weight-bold"
            >Arrendatario</label
          >
          <!--select para mostrar los profesores ya creados-->
          <select
            name="arrendatarios"
            class="form-control text-dark"
            v-model="arrendatario_info"
            :class="validar_datos.arrendatario_info.input_class"
          >
            <option value="default" selected>Seleccionar Arrendatario</option>
            <option
              v-for="arrendatario in listado_arrendatarios"
              :key="arrendatario"
              :value="arrendatario"
            >
              {{ arrendatario.documento_identidad }}
            </option>
          </select>

          <div
            :class="validar_datos.arrendatario_info.div_class"
            v-html="validar_datos.arrendatario_info.msg"
          ></div>
        </div>
        <!-- v-on:click asigna un evento que al pulsar llame a la funcion crear-->
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="crearArrendatarioAula"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearArrendatarioAula()"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#crearArrendatarioAula").modal({
    show: false,
    backdrop: "static",
  });
});

export default {
  //propiedades donde tenemos acceso a las variables del componente padre
  props: ["listado_aulas", "listado_arrendatarios"],

  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      arrendatario_info: "default", // Guardamos los datos del alumno seleccionado en el select.
      aula_info: "default", // Guardamos SOLO el ID del curso seleccionado en el select.
      nuevoArrendatarioAula: {},
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        aula_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        arrendatario_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    //funcion para crear registros en la base de datos
    crearArrendatarioAula() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        // Almacenar en nuevoArrendatarioAula el id del aula y el dni del arrendatario.

        this.nuevoArrendatarioAula = {
          arrendatario_documento_identidad:
            this.arrendatario_info.documento_identidad,
          aulas_id: this.aula_info,
        };

        let arrendatarioAulaStore = "arrendatarioPorAula"; //almaceno en la variable la ruta que accede al metodo store del controlador
        axios
          .post(arrendatarioAulaStore, this.nuevoArrendatarioAula)
          .then((response) => {
            this.resultado.select.fila_editada = response.data.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })
          //cuando todo se haya completado muestra notificacion en el padre
          .finally(() => {
            $("#crearArrendatarioAula").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      }
    },

    validarDatos() {
      let datosValidos = false;

      // ARRENDATARIO DOCUMENTO IDENTIDAD (OBLIGATORIO)

      if (this.arrendatario_info === "default") {
        this.validar_datos.arrendatario_info.valid = false;
        this.validar_datos.arrendatario_info.msg =
          "Debe seleccionarse un <strong>Arrendatario</strong>.";
        this.validar_datos.arrendatario_info.input_class = "is-invalid";
        this.validar_datos.arrendatario_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.arrendatario_info.valid = true;
        this.validar_datos.arrendatario_info.msg =
          "El <strong>Arrendatario</strong> es válido.";
        this.validar_datos.arrendatario_info.input_class = "is-valid";
        this.validar_datos.arrendatario_info.div_class = "valid-feedback";
      }

      // AULA (OBLIGATORIO)

      if (this.aula_info === "default") {
        this.validar_datos.aula_info.valid = false;
        this.validar_datos.aula_info.msg =
          "Debe seleccionarse una <strong>Aula</strong>.";
        this.validar_datos.aula_info.input_class = "is-invalid";
        this.validar_datos.aula_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.aula_info.valid = true;
        this.validar_datos.aula_info.msg =
          "El <strong>Aula</strong> es válido.";
        this.validar_datos.aula_info.input_class = "is-valid";
        this.validar_datos.aula_info.div_class = "valid-feedback";
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.arrendatario_info.valid &&
        this.validar_datos.aula_info.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },

    limpiarFormulario() {
      this.arrendatario_info = "default";
      this.aula_info = "default";
      this.nuevoArrendatarioAula = {};

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.validar_datos = {
        aula_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        arrendatario_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      };
    },
  },
};
</script>