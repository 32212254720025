<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el regsitro-->
  <div class="modal" id="crearProfesor">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="crearProfesorLabel">Crear Profesor</h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <label for="documento_identidad" class="text-dark font-weight-bold"
            >Documento de identidad</label
          >
          <!-- v-model almacenamos en la variable los datos introducidos en el input -->
          <input
            type="text"
            class="form-control text-dark"
            name="documento_identidad"
            v-model="nuevoProfesor.documento_identidad"
            :class="validar_datos.documento_identidad.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.documento_identidad.div_class"
            v-html="validar_datos.documento_identidad.msg"
          ></div>

          <label for="nombre" class="text-dark font-weight-bold"
            >Nombre del Profesor</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="nombre"
            v-model="nuevoProfesor.nombre"
            :class="validar_datos.nombre.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.nombre.div_class"
            v-html="validar_datos.nombre.msg"
          ></div>

          <label for="apellidos" class="text-dark font-weight-bold"
            >Apellidos</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="apellidos"
            v-model="nuevoProfesor.apellidos"
            :class="validar_datos.apellidos.input_class"
            v-on:blur="validarDatos()"
          />
          <div
            :class="validar_datos.apellidos.div_class"
            v-html="validar_datos.apellidos.msg"
          ></div>
        </div>
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="crearProfesor"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearProfesor()"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#crearProfesor").modal({
    show: false,
    backdrop: "static",
  });
});

export default {
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      nuevoProfesor: {
        documento_identidad: null,
        nombre: null,
        apellidos: null,
      },
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        documento_identidad: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        nombre: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        apellidos: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    //funcion para crear registros en la base de datos
    crearProfesor() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        let profesorStore = "profesores"; //almaceno en la variable la ruta que accede al metodo store del controlador
        axios
          .post(profesorStore, this.nuevoProfesor)
          .then((response) => {
            this.resultado.select.fila_editada = this.nuevoProfesor.documento_identidad;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })
          //cuando todo se haya completado muestra notificacion en el padre
          .finally(() => {
            $("#crearProfesor").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      } else {
        this.mostrarErrores();
      }
    },

    validarDatos() {
      let datosValidos = false;

      // DOCUMENTO IDENTIDAD

      if (this.nuevoProfesor.documento_identidad !== null) {
        if (this.nuevoProfesor.documento_identidad.length === 0) {
          this.validar_datos.documento_identidad.valid = false;
          this.validar_datos.documento_identidad.msg =
            "El <strong>Documento de Identidad</strong> es obligatorio.";
          this.validar_datos.documento_identidad.input_class = "is-invalid";
          this.validar_datos.documento_identidad.div_class = "invalid-feedback";
        } else {
          // Comprobar formato
          if (
            /^([a-zA-Z0-9]{9})$/.test(
              this.nuevoProfesor.documento_identidad
            )
          ) {
            this.validar_datos.documento_identidad.valid = true;
            this.validar_datos.documento_identidad.msg =
              "El <strong>Documento de Identidad</strong> es válido";
            this.validar_datos.documento_identidad.input_class = "is-valid";
            this.validar_datos.documento_identidad.div_class = "valid-feedback";
          } else {
            this.validar_datos.documento_identidad.valid = false;
            this.validar_datos.documento_identidad.msg =
              "El <strong>Documento de Identidad</strong> no tiene un formato válido.";
            this.validar_datos.documento_identidad.input_class = "is-invalid";
            this.validar_datos.documento_identidad.div_class =
              "invalid-feedback";
          }
        }
      }

      // NOMBRE

      if (this.nuevoProfesor.nombre !== null) {
        if (this.nuevoProfesor.nombre.length === 0) {
          this.validar_datos.nombre.valid = false;
          this.validar_datos.nombre.msg =
            "El <strong>Nombre del Profesor</strong> es obligatorio.";
          this.validar_datos.nombre.input_class = "is-invalid";
          this.validar_datos.nombre.div_class = "invalid-feedback";
        } else {
          // Comprobar longitud
          if (
            this.nuevoProfesor.nombre.length > 0 &&
            this.nuevoProfesor.nombre.length <= 45
          ) {
            this.validar_datos.nombre.valid = true;
            this.validar_datos.nombre.msg =
              "El <strong>Nombre del Profesor</strong> es válido";
            this.validar_datos.nombre.input_class = "is-valid";
            this.validar_datos.nombre.div_class = "valid-feedback";
          } else {
            this.validar_datos.nombre.valid = false;
            this.validar_datos.nombre.msg =
              "El <strong>Nombre del Profesor</strong> no puede superar los 45 caracteres";
            this.validar_datos.nombre.input_class = "is-invalid";
            this.validar_datos.nombre.div_class = "invalid-feedback";
          }
        }
      }

      // APELLIDOS

      if (this.nuevoProfesor.apellidos !== null) {
        if (this.nuevoProfesor.apellidos.length === 0) {
          this.validar_datos.apellidos.valid = false;
          this.validar_datos.apellidos.msg =
            "Los <strong>Apellidos</strong> son obligatorios.";
          this.validar_datos.apellidos.input_class = "is-invalid";
          this.validar_datos.apellidos.div_class = "invalid-feedback";
        } else {
          // Comprobar longitud
          if (
            this.nuevoProfesor.apellidos.length > 0 &&
            this.nuevoProfesor.apellidos.length <= 45
          ) {
            this.validar_datos.apellidos.valid = true;
            this.validar_datos.apellidos.msg =
              "Los <strong>Apellidos</strong> son válidos";
            this.validar_datos.apellidos.input_class = "is-valid";
            this.validar_datos.apellidos.div_class = "valid-feedback";
          } else {
            this.validar_datos.apellidos.valid = false;
            this.validar_datos.apellidos.msg =
              "Los <strong>Apellidos</strong> no puede superar los 45 caracteres";
            this.validar_datos.apellidos.input_class = "is-invalid";
            this.validar_datos.apellidos.div_class = "invalid-feedback";
          }
        }
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.documento_identidad.valid &&
        this.validar_datos.nombre.valid &&
        this.validar_datos.apellidos.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    /* mostrarErrores() -> Informa al usuario de los campos erróneos cuando trata de enviar los datos al servidor sin 
      haber rellenado previamente todos los campos del formulario. */

    mostrarErrores() {
      this.success = "";

      // DOCUMENTO IDENTIDAD.
      if (this.nuevoProfesor.documento_identidad === null) {
        this.validar_datos.documento_identidad.valid = false;
        this.validar_datos.documento_identidad.msg =
          "El <strong>Documento de Identidad</strong> es obligatorio.";
        this.validar_datos.documento_identidad.input_class = "is-invalid";
        this.validar_datos.documento_identidad.div_class = "invalid-feedback";
      }

      // NOMBRE.
      if (this.nuevoProfesor.nombre === null) {
        this.validar_datos.nombre.valid = false;
        this.validar_datos.nombre.msg =
          "El <strong>Nombre del Profesor</strong> es obligatorio.";
        this.validar_datos.nombre.input_class = "is-invalid";
        this.validar_datos.nombre.div_class = "invalid-feedback";
      }

      // APELLIDOS.
      if (this.nuevoProfesor.apellidos === null) {
        this.validar_datos.apellidos.valid = false;
        this.validar_datos.apellidos.msg =
          "Los <strong>Apellidos</strong> son obligatorios.";
        this.validar_datos.apellidos.input_class = "is-invalid";
        this.validar_datos.apellidos.div_class = "invalid-feedback";
      }
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },

    limpiarFormulario() {
      this.nuevoProfesor = {
        documento_identidad: null,
        nombre: null,
        apellidos: null,
      };

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.validar_datos = {
        documento_identidad: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        nombre: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        apellidos: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      };
    },
  },
};
</script>