<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el regsitro-->
  <div class="modal" id="crearDiploma">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="crearDiplomaLabel">Crear diploma</h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <fieldset v-if="alumno_info !== 'default'">
            <legend>Información del alumno</legend>
            <p class="text-danger font-weight-bold">
              Nombre:
              <label for="nombreAlumno" class="text-dark font-weight-bold">{{
                alumno_info.nombre
              }}</label>
            </p>
            <p class="text-danger font-weight-bold">
              Apellidos:
              <label for="apellidosAlumno" class="text-dark font-weight-bold">
                {{ alumno_info.apellidos }}</label
              >
            </p>
            <p for="alumnoDNI" class="text-danger font-weight-bold">
              DNI:
              <label for="dniAlumno" class="text-dark font-weight-bold">
                {{ alumno_info.documento_identidad }}</label
              >
            </p>
          </fieldset>

          <fieldset v-if="curso_info !== 'default'">
            <legend>Información del curso</legend>
            <p class="text-danger font-weight-bold">
              ID Edición:
              <label for="edicionCurso" class="text-dark font-weight-bold">{{
                curso_info.id_edicion
              }}</label>
            </p>
            <p class="text-danger font-weight-bold">
              ID Curso:
              <label for="idCurso" class="text-dark font-weight-bold">
                {{ curso_info.id_curso }}</label
              >
            </p>
            <p for="nombreCurso" class="text-danger font-weight-bold">
              Nombre Curso:
              <label for="nombreCurso" class="text-dark font-weight-bold">
                {{ curso_info.nombre }}</label
              >
            </p>
            <p for="horasCurso" class="text-danger font-weight-bold">
              Horas:
              <label for="horasCurso" class="text-dark font-weight-bold">
                {{ curso_info.horas }}</label
              >
            </p>
          </fieldset>

          <label for="alumno" class="text-dark font-weight-bold">Alumno</label>

          <select
            id="alumnos"
            class="form-control text-dark"
            v-model="alumno_info"
            :class="validar_datos.alumno_info.input_class"
          >
            <option value="default">Seleccionar alumno</option>
            <option
              v-for="alumno in listado_alumnos"
              v-bind:key="alumno"
              :value="alumno"
            >
              {{ alumno.documento_identidad }}
            </option>
          </select>

          <div
            :class="validar_datos.alumno_info.div_class"
            v-html="validar_datos.alumno_info.msg"
          ></div>

          <div v-if="alumno_info !== 'default'">
            <label for="curso" class="text-dark font-weight-bold">Curso</label>

            <select
              id="cursos"
              class="form-control text-dark"
              v-model="curso_info"
              :class="validar_datos.curso_info.input_class"
            >
              <option value="default">Seleccionar curso</option>
              <option
                v-for="curso in listado_cursos"
                v-bind:key="curso"
                :value="curso"
              >
                {{ curso.id_curso }}-{{ curso.nombre }}
              </option>
            </select>

            <div
              :class="validar_datos.curso_info.div_class"
              v-html="validar_datos.curso_info.msg"
            ></div>
          </div>

          <div v-if="alumno_info !== 'default' && curso_info !== 'default'">
            <label for="calificacion" class="text-dark font-weight-bold"
              >Calificación</label
            >

            <select
              id="calificaciones"
              class="form-control text-dark"
              v-model="calificacion_info"
            >
              <option value="">Seleccionar calificación</option>
              <option
                v-for="calificacion in listado_calificaciones"
                v-bind:key="calificacion"
                :value="calificacion"
              >
                {{ calificacion }}
              </option>
            </select>

            <label for="archivo_diplomas" class="text-dark font-weight-bold"
              >Archivo del diploma</label
            >

            <select
              id="archivo_diplomas"
              class="form-control text-dark"
              v-model="archivo_diploma_info"
            >
              <option value="">Seleccionar archivo diploma</option>
              <option
                v-for="archivoDiploma in listado_archivo_diplomas"
                v-bind:key="archivoDiploma"
                :value="archivoDiploma"
              >
                {{ archivoDiploma }}
              </option>
            </select>

            <label for="dni" class="text-dark font-weight-bold"
              >Fecha de emisión</label
            >

            <!--Utilización de vue datepicker -->
            <datepicker
              v-model:value="fecha_emision"
              v-on:clear="
                fecha_emision = '';
                validarDatos();
              "
              v-on:close="validarDatos()"
              :editable="true"
              :input-class="validar_datos.fecha_emision.input_class"
              :format="'DD-MM-YYYY'"
              :value-type="'format'"
              :lang="'es.es'"
            ></datepicker>

            <div
              :class="validar_datos.fecha_emision.div_class"
              v-html="validar_datos.fecha_emision.msg"
            ></div>
          </div>
        </div>
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="crearDiploma"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearDiploma()"
          >
            Crear diploma
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#crearDiploma").modal({
    show: false,
    backdrop: "static",
  });
});

import moment from "moment";

export default {
  //propiedades donde tenemos acceso a las variables del componente padre
  props: [
    "listado_alumnos",
    "listado_cursos",
    "listado_calificaciones",
    "listado_archivo_diplomas",
  ],
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      alumno_info: "default", // Guardamos los datos del alumno seleccionado en el select #alumnos.
      curso_info: "default", // Guardamos los datos del curso seleccionado en el select #cursos.
      calificacion_info: "", // Guardar la calificación seleccionada en el select #calificaciones.
      archivo_diploma_info: "", // Guardamos el archivo_diploma seleccionado en el select #archivo_diplomas.
      fecha_emision: "",
      nuevoDiploma: {},
      resultado: {
        // Almacenar el resultado de las operaciones entre el cliente - servidor.
        success: "",
        error: "",
        select: {
          fila_editada: 0, // ID del row que ha sido editada para poderla resaltar.
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        alumno_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        curso_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        fecha_emision: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "form-control",
        },
      },
    };
  },
  methods: {
    //funcion para crear registros en la base de datos
    crearDiploma() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        // Almacenar en nuevoDiploma los datos del formulario.

        this.nuevoDiploma = {
          fecha_emision: this.fecha_emision,
          calificacion: this.calificacion_info,
          archivo_diplomas: this.archivo_diploma_info,
          alumnos_documento_identidad: this.alumno_info.documento_identidad,
          ediciones_cursos_id: this.curso_info.id_edicion,
        };

        let diplomasStore = "diplomas"; //almaceno en la variable la ruta que accede al metodo store del controlador
        axios
          .post(diplomasStore, this.nuevoDiploma)
          .then((response) => {
            this.resultado.select.fila_editada = response.data.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            this.resultado.error = error.response.data.mensaje;
            this.resultado.success = "";
          })
          //cuando todo se haya completado muestra notificacion en el padre
          .finally(() => {
            $("#crearDiploma").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      }
    },

    validarDatos() {
      let datosValidos = false;

      // ALUMNOS DOCUMENTO IDENTIDAD (OBLIGATORIO)

      if (this.alumno_info === "default") {
        this.validar_datos.alumno_info.valid = false;
        this.validar_datos.alumno_info.msg =
          "Debe seleccionarse un <strong>Alumno</strong>.";
        this.validar_datos.alumno_info.input_class = "is-invalid";
        this.validar_datos.alumno_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.alumno_info.valid = true;
        this.validar_datos.alumno_info.msg =
          "El <strong>Alumno</strong> es válido.";
        this.validar_datos.alumno_info.input_class = "is-valid";
        this.validar_datos.alumno_info.div_class = "valid-feedback";
      }

      // EDICIONES CURSOS ID (OBLIGATORIO)

      if (this.curso_info === "default") {
        this.validar_datos.curso_info.valid = false;
        this.validar_datos.curso_info.msg =
          "Debe seleccionarse un <strong>Curso</strong>.";
        this.validar_datos.curso_info.input_class = "is-invalid";
        this.validar_datos.curso_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.curso_info.valid = true;
        this.validar_datos.curso_info.msg =
          "El <strong>Curso</strong> es válido.";
        this.validar_datos.curso_info.input_class = "is-valid";
        this.validar_datos.curso_info.div_class = "valid-feedback";
      }

      // FECHA EMISIÓN (PUEDE SER NULO)

      if (this.fecha_emision.length > 0) {
        if (moment(this.fecha_emision, "DD-MM-YYYY", true).isValid()) {
          this.validar_datos.fecha_emision.valid = true;
          this.validar_datos.fecha_emision.msg =
            "La <strong>Fecha de emisión</strong> es válida.";
          this.validar_datos.fecha_emision.input_class =
            "form-control text-dark is-valid";
          this.validar_datos.fecha_emision.div_class = "d-block valid-feedback";
        } else {
          this.validar_datos.fecha_emision.valid = false;
          this.validar_datos.fecha_emision.msg =
            "La <strong>Fecha de emisión</strong> no es válida.";
          this.validar_datos.fecha_emision.input_class =
            "form-control text-dark is-invalid";
          this.validar_datos.fecha_emision.div_class =
            "d-block invalid-feedback";
        }
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.alumno_info.valid &&
        this.validar_datos.curso_info.valid &&
        this.validar_datos.fecha_emision.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },

    limpiarFormulario() {
      this.alumno_info = "default";
      this.curso_info = "default";
      this.calificacion_info = "";
      this.archivo_diploma_info = "";
      this.fecha_emision = "";
      this.nuevoDiploma = {};

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.validar_datos = {
        alumno_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        curso_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        fecha_emision: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "form-control",
        },
      };
    },
  },
};
</script>