<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para editar el registro-->
  <div class="modal" id="editarArrendatario">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="editarArrendatarioLabel">
            Editar Arrendatario
          </h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <label for="nombre" class="text-dark font-weight-bold">Nombre</label>
          <!-- v-model almacenamos en la variable los datos introducidos en el input -->
          <input
            type="text"
            class="form-control text-dark"
            name="nombre"
            v-model="editaArrendatario.nombre"
            :class="validar_datos.nombre.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.nombre.div_class"
            v-html="validar_datos.nombre.msg"
          ></div>

          <label for="apellidos" class="text-dark font-weight-bold"
            >Apellidos</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="apellidos"
            v-model="editaArrendatario.apellidos"
            :class="validar_datos.apellidos.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.apellidos.div_class"
            v-html="validar_datos.apellidos.msg"
          ></div>

          <label for="telefono" class="text-dark font-weight-bold"
            >Telefono</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="telefono"
            v-model="editaArrendatario.telefono"
            :class="validar_datos.telefono.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.telefono.div_class"
            v-html="validar_datos.telefono.msg"
          ></div>

          <label for="correo" class="text-dark font-weight-bold">Correo</label>

          <input
            type="text"
            class="form-control text-dark"
            name="correo"
            v-model="editaArrendatario.correo"
            :class="validar_datos.correo.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.correo.div_class"
            v-html="validar_datos.correo.msg"
          ></div>
        </div>
        <!-- v-on:click asigna un evento que al pulsar llame a la funcion editar...-->
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="editarArrendatario"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="actualizarArrendatario()"
          >
            Guardar Cambios
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#editarArrendatario").modal({
    show: false,
    backdrop: "static",
  });
});

export default {
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      editaArrendatario: {
        documento_identidad: null,
        nombre: null,
        apellidos: null,
        telefono: null,
        correo: null,
      },
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        nombre: {
          msg: "",
          valid: true, // inicializado en true cuando sean campos no obligatorios.
          div_class: "",
          input_class: "",
        },
        apellidos: {
          msg: "",
          valid: true, // inicializado en true cuando sean campos no obligatorios.
          div_class: "",
          input_class: "",
        },
        telefono: {
          msg: "",
          valid: true, // inicializado en true cuando sean campos no obligatorios.
          div_class: "",
          input_class: "",
        },
        correo: {
          msg: "",
          valid: true, // inicializado en true cuando sean campos no obligatorios.
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    // Rellenamos los datos del formulario con el registro que queremos editar
    showForm(editaArrendatario) {
      (this.editaArrendatario.documento_identidad =
        editaArrendatario.documento_identidad),
        (this.editaArrendatario.nombre = editaArrendatario.nombre),
        (this.editaArrendatario.apellidos = editaArrendatario.apellidos),
        (this.editaArrendatario.telefono = editaArrendatario.telefono),
        (this.editaArrendatario.correo = editaArrendatario.correo),
        this.validarDatos();
      $("#editarArrendatario").modal("show");
    },

    //funcion para editar registros en la base de datos
    actualizarArrendatario() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        let arrendatarioUpdate =
          "arrendatarios/" + this.editaArrendatario.documento_identidad; //almaceno en la variable la ruta que accede al metodo update del controlador
        axios
          .patch(arrendatarioUpdate, this.editaArrendatario)
          .then((response) => {
            this.resultado.select.fila_editada =
              this.editaArrendatario.documento_identidad;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })

          //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
          .finally(() => {
            $("#editarArrendatario").modal("hide");
            this.$emit("mostrarNotificacion", this.resultado);
            this.bloquear_boton = false;
          });
      } else {
        this.mostrarErrores();
      }
    },

    validarDatos() {
      let datosValidos = false;

      // NOMBRE

      if (this.editaArrendatario.nombre !== null) {
        if (this.editaArrendatario.nombre.length > 0) {
          // Comprobar longitud
          if (
            this.editaArrendatario.nombre.length > 0 &&
            this.editaArrendatario.nombre.length <= 45
          ) {
            this.validar_datos.nombre.valid = true;
            this.validar_datos.nombre.msg =
              "El <strong>Nombre</strong> es válido";
            this.validar_datos.nombre.input_class = "is-valid";
            this.validar_datos.nombre.div_class = "valid-feedback";
          } else {
            this.validar_datos.nombre.valid = false;
            this.validar_datos.nombre.msg =
              "El <strong>Nombre</strong> no puede superar los 45 caracteres";
            this.validar_datos.nombre.input_class = "is-invalid";
            this.validar_datos.nombre.div_class = "invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.editaArrendatario.nombre = "";
          this.validar_datos.nombre.valid = true;
          this.validar_datos.nombre.msg = "";
          this.validar_datos.nombre.input_class = "";
          this.validar_datos.nombre.div_class = "";
        }
      }

      // APELLIDOS

      if (this.editaArrendatario.apellidos !== null) {
        if (this.editaArrendatario.apellidos.length > 0) {
          // Comprobar longitud
          if (
            this.editaArrendatario.apellidos.length > 0 &&
            this.editaArrendatario.apellidos.length <= 45
          ) {
            this.validar_datos.apellidos.valid = true;
            this.validar_datos.apellidos.msg =
              "Los <strong>Apellidos</strong> son válidos";
            this.validar_datos.apellidos.input_class = "is-valid";
            this.validar_datos.apellidos.div_class = "valid-feedback";
          } else {
            this.validar_datos.apellidos.valid = false;
            this.validar_datos.apellidos.msg =
              "Los <strong>Apellidos</strong> no puede superar los 45 caracteres";
            this.validar_datos.apellidos.input_class = "is-invalid";
            this.validar_datos.apellidos.div_class = "invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.editaArrendatario.apellidos = "";
          this.validar_datos.apellidos.valid = true;
          this.validar_datos.apellidos.msg = "";
          this.validar_datos.apellidos.input_class = "";
          this.validar_datos.apellidos.div_class = "";
        }
      }

      // TELEFONO

      if (this.editaArrendatario.telefono !== null) {
        if (this.editaArrendatario.telefono.length > 0) {
          // Comprobar longitud
          if (
            this.editaArrendatario.telefono.length > 0 &&
            this.editaArrendatario.telefono.length <= 20
          ) {
            // Validar formato regex.

            if (
              /^\+{1,1}[1-9]+\s?\d+$|^\d+\s?\d+$/.test(
                this.editaArrendatario.telefono
              )
            ) {
              this.validar_datos.telefono.valid = true;
              this.validar_datos.telefono.msg =
                "El <strong>Teléfono</strong> es válido";
              this.validar_datos.telefono.input_class = "is-valid";
              this.validar_datos.telefono.div_class = "valid-feedback";
            } else {
              this.validar_datos.telefono.valid = false;
              this.validar_datos.telefono.msg =
                "El <strong>Teléfono</strong> no tiene un formato válido.";
              this.validar_datos.telefono.input_class = "is-invalid";
              this.validar_datos.telefono.div_class = "invalid-feedback";
            }
          } else {
            this.validar_datos.telefono.valid = false;
            this.validar_datos.telefono.msg =
              "El <strong>Teléfono</strong> no puede superar los 20 caracteres";
            this.validar_datos.telefono.input_class = "is-invalid";
            this.validar_datos.telefono.div_class = "invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.editaArrendatario.telefono = "";
          this.validar_datos.telefono.valid = true;
          this.validar_datos.telefono.msg = "";
          this.validar_datos.telefono.input_class = "";
          this.validar_datos.telefono.div_class = "";
        }
      }

      // CORREO

      if (this.editaArrendatario.correo !== null) {
        if (this.editaArrendatario.correo.length > 0) {
          if (
            this.editaArrendatario.correo.length > 0 &&
            this.editaArrendatario.correo.length <= 128
          ) {
            // Comprobar formato de la dirección de correo (regex).

            if (
              /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9]+([.][a-zA-Z0-9]+)*[.][a-zA-Z]{1,5}/.test(
                this.editaArrendatario.correo
              )
            ) {
              this.validar_datos.correo.valid = true;
              this.validar_datos.correo.msg =
                "El <strong>Correo</strong> es válido.";
              this.validar_datos.correo.input_class = "is-valid";
              this.validar_datos.correo.div_class = "valid-feedback";
            } else {
              this.validar_datos.correo.valid = false;
              this.validar_datos.correo.msg =
                "El <strong>Correo</strong> no tiene un formato válido.";
              this.validar_datos.correo.input_class = "is-invalid";
              this.validar_datos.correo.div_class = "invalid-feedback";
            }
          } else {
            this.validar_datos.correo = "";
            this.validar_datos.correo.valid = false;
            this.validar_datos.correo.msg =
              "El <strong>Correo</strong> no puede superar los 128 caracteres.";
            this.validar_datos.correo.input_class = "is-invalid";
            this.validar_datos.correo.div_class = "invalid-feedback";
          }
        }
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.nombre.valid &&
        this.validar_datos.apellidos.valid &&
        this.validar_datos.telefono.valid &&
        this.validar_datos.correo.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },

    limpiarFormulario() {
      this.editaArrendatario = {
        nombre: null,
        apellidos: null,
        telefono: null,
        correo: null,
      };

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.validar_datos = {
        nombre: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        apellidos: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        telefono: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        correo: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
      };
    },
  },
};
</script>