<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el resgitro-->
  <div class="modal" id="crearProfesoresCurso">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="crearProfesoresCursoLabel">
            Añadir Profesor al Curso
          </h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <!--  mostramos información del alumno seleccionado -->
          <fieldset v-if="profesor_info !== 'default'">
            <!--condicion para mostrar información solo cuando se seleccione-->
            <legend>Información del Profesor</legend>
            <p class="text-danger font-weight-bold">
              Nombre:
              <label for="nombreProfesor" class="text-dark font-weight-bold">{{
                profesor_info.nombre
              }}</label>
            </p>
            <p class="text-danger font-weight-bold">
              Apellidos:
              <label for="apellidosProfesor" class="text-dark font-weight-bold">
                {{ profesor_info.apellidos }}</label
              >
            </p>
            <p for="profesorDNI" class="text-danger font-weight-bold">
              DNI:
              <label for="dniProfesor" class="text-dark font-weight-bold">
                {{ profesor_info.documento_identidad }}</label
              >
            </p>
          </fieldset>
          <!--Ediciones-->
          <label for="edicion" class="text-dark font-weight-bold"
            >Código del Curso</label
          >
          <!--select para mostrar los cursos ya creados-->
          <select
            name="ediciones"
            class="form-control text-dark"
            v-model="edicion_info"
            :class="validar_datos.edicion_info.input_class"
          >
            <option value="default" selected>
              Seleccionar Código del Curso
            </option>
            <option
              v-for="edicion in listado_ediciones"
              :key="edicion"
              :value="edicion.id"
            >
              {{ edicion.cursos_id }}
            </option>
          </select>

          <div
            :class="validar_datos.edicion_info.div_class"
            v-html="validar_datos.edicion_info.msg"
          ></div>

          <!--profesores-->
          <label for="profesor" class="text-dark font-weight-bold"
            >Profesor</label
          >
          <!--select para mostrar los profesores ya creados-->
          <select
            name="profesores"
            class="form-control text-dark"
            v-model="profesor_info"
            :class="validar_datos.profesor_info.input_class"
          >
            <option value="default" selected>Seleccionar Profesor</option>
            <option
              v-for="profesor in listado_profesores"
              :key="profesor"
              :value="profesor"
            >
              {{ profesor.documento_identidad }}
            </option>
          </select>

          <div
            :class="validar_datos.profesor_info.div_class"
            v-html="validar_datos.profesor_info.msg"
          ></div>
        </div>
        <!-- v-on:click asigna un evento que al pulsar llame a la funcion crear-->
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="crearProfesoresCurso"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearProfesorCurso()"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#crearProfesoresCurso").modal({
    show: false,
    backdrop: "static",
  });
});

export default {
  //propiedades donde tenemos acceso a las variables del componente padre
  props: ["listado_ediciones", "listado_profesores"],

  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      profesor_info: "default", // Guardamos los datos del alumno seleccionado en el select.
      edicion_info: "default", // Guardamos SOLO el ID del curso seleccionado en el select.
      nuevoProfesorCurso: {},
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        edicion_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        profesor_info: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    //funcion para crear registros en la base de datos
    crearProfesorCurso() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;
        // Almacenar en nuevoAlumnoCurso el id del curso y el dni del alumno.

        this.nuevoProfesorCurso = {
          profesores_documento_identidad:
            this.profesor_info.documento_identidad,
          ediciones_cursos_id: this.edicion_info,
        };

        let profesorCursoStore = "profesoresPorCurso"; //almaceno en la variable la ruta que accede al metodo store del controlador
        axios
          .post(profesorCursoStore, this.nuevoProfesorCurso)
          .then((response) => {
            this.resultado.select.fila_editada = response.data.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })
          //cuando todo se haya completado muestra notificacion en el padre
          .finally(() => {
            $("#crearProfesoresCurso").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      }
    },
    validarDatos() {
      let datosValidos = false;

      // EDICION_ID (OBLIGATORIO)

      if (this.edicion_info === "default") {
        this.validar_datos.edicion_info.valid = false;
        this.validar_datos.edicion_info.msg =
          "Debe seleccionarse un <strong>Curso</strong>.";
        this.validar_datos.edicion_info.input_class = "is-invalid";
        this.validar_datos.edicion_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.edicion_info.valid = true;
        this.validar_datos.edicion_info.msg =
          "El <strong>Curso</strong> es válido.";
        this.validar_datos.edicion_info.input_class = "is-valid";
        this.validar_datos.edicion_info.div_class = "valid-feedback";
      }

      // PROFESOR (OBLIGATORIO)

      if (this.profesor_info === "default") {
        this.validar_datos.profesor_info.valid = false;
        this.validar_datos.profesor_info.msg =
          "Debe seleccionarse un <strong>Profesor</strong>.";
        this.validar_datos.profesor_info.input_class = "is-invalid";
        this.validar_datos.profesor_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.profesor_info.valid = true;
        this.validar_datos.profesor_info.msg =
          "El <strong>Profesor</strong> es válido.";
        this.validar_datos.profesor_info.input_class = "is-valid";
        this.validar_datos.profesor_info.div_class = "valid-feedback";
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.edicion_info.valid &&
        this.validar_datos.profesor_info.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },

    limpiarFormulario() {
      this.profesor_info = "default";
      this.edicion_info = "default";
      this.nuevoProfesorCurso = {};

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      // VALIDACIONES
      this.validar_datos = {
        edicion_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        profesor_info: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
      };
    },
  },
};
</script>