<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para editar el curso-->
  <div class="modal" id="editarEdicion">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="editarEdicionLabel">
            Editar edición del curso
          </h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <label for="cursos_id" class="text-dark font-weight-bold"
            >Curso</label
          >
          <select
            id="cursos"
            class="form-control text-dark"
            v-model="editaEdicion.cursos_id"
            :class="validar_datos.cursos_id.input_class"
          >
            <option value="default">Seleccionar curso</option>
            <option
              v-for="curso in listado_cursos"
              v-bind:key="curso"
              :value="curso.id"
            >
              {{ curso.id }} {{ curso.nombre }}
              <!--Para cargar el curso seleccionado al editar-->
            </option>
          </select>

          <div
            :class="validar_datos.cursos_id.div_class"
            v-html="validar_datos.cursos_id.msg"
          ></div>

          <label for="horas" class="text-dark font-weight-bold"
            >Horas del Curso</label
          >
          <!-- v-model almacenamos en la variable los datos introducidos en el input -->
          <input
            type="text"
            class="form-control text-dark"
            name="horas"
            v-model="editaEdicion.horas"
            :class="validar_datos.horas.input_class"
          />

          <div
            :class="validar_datos.horas.div_class"
            v-html="validar_datos.horas.msg"
          ></div>

          <label for="fecha_inicio" class="text-dark font-weight-bold"
            >Fecha inicio del Curso</label
          >

          <!--Utilización de vue datepicker -->
          <datepicker
            v-model:value="editaEdicion.fecha_inicio"
            v-on:clear="
              editaEdicion.fecha_inicio = '';
              validarDatos();
            "
            v-on:close="validarDatos()"
            :editable="true"
            :input-class="validar_datos.fecha_inicio.input_class"
            :format="'DD-MM-YYYY'"
            :value-type="'format'"
            :lang="'es.es'"
          ></datepicker>

          <div
            :class="validar_datos.fecha_inicio.div_class"
            v-html="validar_datos.fecha_inicio.msg"
          ></div>

          <label for="fecha_fin" class="text-dark font-weight-bold"
            >Fecha fin del Curso</label
          >

          <!--Utilización de vue datepicker -->
          <datepicker
            v-model:value="editaEdicion.fecha_fin"
            v-on:clear="
              editaEdicion.fecha_fin = '';
              validarDatos();
            "
            v-on:close="validarDatos()"
            :editable="true"
            :input-class="validar_datos.fecha_fin.input_class"
            :format="'DD-MM-YYYY'"
            :value-type="'format'"
            :lang="'es.es'"
          ></datepicker>

          <div
            :class="validar_datos.fecha_fin.div_class"
            v-html="validar_datos.fecha_fin.msg"
          ></div>
        </div>
        <!-- v-on:click asigna un evento que al pulsar llame a la funcion editarCurso-->
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="editarEdicion"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="actualizarEdicion()"
          >
            Guardar Cambios
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#editarEdicion").modal({
    show: false,
    backdrop: "static",
  });
});

import moment from "moment";

export default {
  //propiedades donde tenemos acceso a las variables del componente padre
  props: ["listado_cursos"],
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      editaEdicion: {
        id: null,
        cursos_id: null,
        horas: null,
        fecha_inicio: null,
        fecha_fin: null,
      },
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        cursos_id: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        horas: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        fecha_inicio: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "form-control",
        },
        fecha_fin: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "form-control",
        },
      },
    };
  },
  methods: {
    // Rellenamos los datos del formulario con el curso que queremos editar
    showForm(editaEdicion) {
      (this.editaEdicion.id = editaEdicion.id),
        (this.editaEdicion.cursos_id = editaEdicion.cursos_id),
        (this.editaEdicion.horas = editaEdicion.horas),
        (this.editaEdicion.fecha_inicio = editaEdicion.fecha_inicio),
        (this.editaEdicion.fecha_fin = editaEdicion.fecha_fin),
        this.validarDatos();
      $("#editarEdicion").modal("show");
    },

    //funcion para editar cursos en la base de datos
    actualizarEdicion() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        let edicionUpdate = "edicionesCursos/" + this.editaEdicion.id; //almaceno en la variable la ruta que accede al metodo update del controlador
        axios
          .patch(edicionUpdate, this.editaEdicion)
          .then((response) => {
            this.resultado.select.fila_editada = this.editaEdicion.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })

          //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
          .finally(() => {
            $("#editarEdicion").modal("hide");
            this.enviarNotificacion();
            this.bloquear_boton = false;
          });
      }
    },
    validarDatos() {
      let datosValidos = false;

      // CURSO_ID (OBLIGATORIO)

      if (this.editaEdicion.cursos_id === "default") {
        this.validar_datos.cursos_id.valid = false;
        this.validar_datos.cursos_id.msg =
          "Debe seleccionarse un <strong>Curso</strong>.";
        this.validar_datos.cursos_id.input_class = "is-invalid";
        this.validar_datos.cursos_id.div_class = "invalid-feedback";
      } else {
        this.validar_datos.cursos_id.valid = true;
        this.validar_datos.cursos_id.msg =
          "El <strong>Curso</strong> es válido.";
        this.validar_datos.cursos_id.input_class = "is-valid";
        this.validar_datos.cursos_id.div_class = "valid-feedback";
      }

      // HORAS (PUEDE SER NULO)

      if (this.editaEdicion.horas !== null) {
        if (this.editaEdicion.horas.toString().length > 0) {
          // usamos toString() porque el valor que recibimos es un dato de tipo entero y no podemos usar el método length para calcular la cantidad de carateres.
          if (/^(\d{0,10})$/.test(this.editaEdicion.horas)) {
            this.validar_datos.horas.valid = true;
            this.validar_datos.horas.msg =
              "Las <strong>Horas del curso</strong> son válidas.";
            this.validar_datos.horas.input_class = "is-valid";
            this.validar_datos.horas.div_class = "valid-feedback";
          } else {
            this.validar_datos.horas.valid = false;
            this.validar_datos.horas.msg =
              "Las <strong>Horas del curso</strong> no son válidas.";
            this.validar_datos.horas.input_class = "is-invalid";
            this.validar_datos.horas.div_class = "invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.validar_datos.horas.valid = true;
          this.validar_datos.horas.msg = "";
          this.validar_datos.horas.input_class = "";
          this.validar_datos.horas.div_class = "";
        }
      }

      // FECHA INICIO DEL CURSO (PUEDE SER NULO)

      if (this.editaEdicion.fecha_inicio !== null) {
        if (this.editaEdicion.fecha_inicio.length > 0) {
          if (
            moment(this.editaEdicion.fecha_inicio, "DD-MM-YYYY", true).isValid()
          ) {
            this.validar_datos.fecha_inicio.valid = true;
            this.validar_datos.fecha_inicio.msg =
              "La <strong>Fecha inicio del curso</strong> es válida.";
            this.validar_datos.fecha_inicio.input_class =
              "form-control text-dark is-valid";
            this.validar_datos.fecha_inicio.div_class =
              "d-block valid-feedback";
          } else {
            this.validar_datos.fecha_inicio.valid = false;
            this.validar_datos.fecha_inicio.msg =
              "La <strong>Fecha inicio del curso</strong> no es válida.";
            this.validar_datos.fecha_inicio.input_class =
              "form-control text-dark is-invalid";
            this.validar_datos.fecha_inicio.div_class =
              "d-block invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.validar_datos.fecha_inicio.valid = true;
          this.validar_datos.fecha_inicio.msg = "";
          this.validar_datos.fecha_inicio.input_class = "form-control";
          this.validar_datos.fecha_inicio.div_class = "";
        }
      }

      // FECHA FIN DEL CURSO (PUEDE SER NULO)

      if (this.editaEdicion.fecha_fin !== null) {
        if (this.editaEdicion.fecha_fin.length > 0) {
          if (
            moment(this.editaEdicion.fecha_fin, "DD-MM-YYYY", true).isValid()
          ) {
            this.validar_datos.fecha_fin.valid = true;
            this.validar_datos.fecha_fin.msg =
              "La <strong>Fecha fin del curso</strong> es válida.";
            this.validar_datos.fecha_fin.input_class =
              "form-control text-dark is-valid";
            this.validar_datos.fecha_fin.div_class = "d-block valid-feedback";
          } else {
            this.validar_datos.fecha_fin.valid = false;
            this.validar_datos.fecha_fin.msg =
              "La <strong>Fecha fin del curso</strong> no es válida.";
            this.validar_datos.fecha_fin.input_class =
              "form-control text-dark is-invalid";
            this.validar_datos.fecha_fin.div_class = "d-block invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.validar_datos.fecha_fin.valid = true;
          this.validar_datos.fecha_fin.msg = "";
          this.validar_datos.fecha_fin.input_class = "form-control";
          this.validar_datos.fecha_fin.div_class = "";
        }
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.cursos_id.valid &&
        this.validar_datos.horas.valid &&
        this.validar_datos.fecha_inicio.valid &&
        this.validar_datos.fecha_fin.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },
  },
};
</script>