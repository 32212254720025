<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el curso-->
  <div class="modal" id="crearCurso">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="crearCursoLabel">Crear Curso</h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <label for="id" class="text-dark font-weight-bold"
            >Código Curso</label
          >
          <!-- v-model almacenamos en la variable los datos introducidos en el input -->
          <input
            type="text"
            class="form-control text-dark"
            name="id"
            v-model="nuevoCurso.id"
            :class="validar_datos.id.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.id.div_class"
            v-html="validar_datos.id.msg"
          ></div>

          <label for="nombre" class="text-dark font-weight-bold"
            >Nombre del Curso</label
          >
          <!-- v-model almacenamos en la variable los datos introducidos en el input -->
          <input
            type="text"
            class="form-control text-dark"
            name="nombre"
            v-model="nuevoCurso.nombre"
            :class="validar_datos.nombre.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.nombre.div_class"
            v-html="validar_datos.nombre.msg"
          ></div>
        </div>
        <!-- v-on:click asigna un evento que al pulsar llame a la funcion crearCurso-->
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="crearCurso"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearCurso()"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#crearCurso").modal({
    show: false,
    backdrop: "static",
  });
});

export default {
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      nuevoCurso: {
        id: null,
        nombre: null,
      },
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        id: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        nombre: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    //funcion para crear cursos en la base de datos
    crearCurso() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        let cursoStore = "cursos"; //almaceno en la variable la ruta que accede al metodo store del controlador
        axios
          .post(cursoStore, this.nuevoCurso)
          .then((response) => {
            this.resultado.select.fila_editada = this.nuevoCurso.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })
          //cuando todo se haya completado muestra notificacion en el padre
          .finally(() => {
            $("#crearCurso").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      } else {
        this.mostrarErrores();
      }
    },

    validarDatos() {
      let datosValidos = false;

      // ID

      if (this.nuevoCurso.id !== null) {
        if (this.nuevoCurso.id.length === 0) {
          this.validar_datos.id.valid = false;
          this.validar_datos.id.msg =
            "El <strong>Código Curso</strong> es obligatorio.";
          this.validar_datos.id.input_class = "is-invalid";
          this.validar_datos.id.div_class = "invalid-feedback";
        } else {
          // Comprobar longitud
          if (
            this.nuevoCurso.id.length > 0 &&
            this.nuevoCurso.id.length <= 50
          ) {
            this.validar_datos.id.valid = true;
            this.validar_datos.id.msg =
              "El <strong>Código Curso</strong> es válido";
            this.validar_datos.id.input_class = "is-valid";
            this.validar_datos.id.div_class = "valid-feedback";
          } else {
            this.validar_datos.id.valid = false;
            this.validar_datos.id.msg =
              "El <strong>Código Curso</strong> no puede superar los 50 caracteres";
            this.validar_datos.id.input_class = "is-invalid";
            this.validar_datos.id.div_class = "invalid-feedback";
          }
        }
      }

      // NOMBRE

      if (this.nuevoCurso.nombre !== null) {
        if (this.nuevoCurso.nombre.length === 0) {
          this.validar_datos.nombre.valid = false;
          this.validar_datos.nombre.msg =
            "El <strong>Nombre del Curso</strong> es obligatorio.";
          this.validar_datos.nombre.input_class = "is-invalid";
          this.validar_datos.nombre.div_class = "invalid-feedback";
        } else {
          // Comprobar longitud
          if (
            this.nuevoCurso.nombre.length > 0 &&
            this.nuevoCurso.nombre.length <= 45
          ) {
            this.validar_datos.nombre.valid = true;
            this.validar_datos.nombre.msg =
              "El <strong>Nombre del Curso</strong> es válido";
            this.validar_datos.nombre.input_class = "is-valid";
            this.validar_datos.nombre.div_class = "valid-feedback";
          } else {
            this.validar_datos.nombre.valid = false;
            this.validar_datos.nombre.msg =
              "El <strong>Nombre del Curso</strong> no puede superar los 45 caracteres";
            this.validar_datos.nombre.input_class = "is-invalid";
            this.validar_datos.nombre.div_class = "invalid-feedback";
          }
        }
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (this.validar_datos.id.valid && this.validar_datos.nombre.valid) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    /* mostrarErrores() -> Informa al usuario de los campos erróneos cuando trata de enviar los datos al servidor sin 
      haber rellenado previamente todos los campos del formulario. */

    mostrarErrores() {
      this.success = "";

      // ID.
      if (this.nuevoCurso.id === null) {
        this.validar_datos.id.valid = false;
        this.validar_datos.id.msg =
          "El <strong>Código Curso</strong> es obligatorio.";
        this.validar_datos.id.input_class = "is-invalid";
        this.validar_datos.id.div_class = "invalid-feedback";
      }

      // NOMBRE.
      if (this.nuevoCurso.nombre === null) {
        this.validar_datos.nombre.valid = false;
        this.validar_datos.nombre.msg =
          "El <strong>Nombre del Curso</strong> es obligatorio.";
        this.validar_datos.nombre.input_class = "is-invalid";
        this.validar_datos.nombre.div_class = "invalid-feedback";
      }
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },

    limpiarFormulario() {
      this.nuevoCurso = {
        id: null,
        nombre: null,
      };

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.validar_datos = {
        id: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        nombre: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      };
    },
  },
};
</script>