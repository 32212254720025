<template>
  <div class="modal" id="crearPagoRealizado">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="crearPagoRealizadoLabel">
            Agregar nueva operación para un pago de un arrendatario
          </h3>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <fieldset v-if="arrendatario_info !== 'default'">
            <legend>Información del arrendatario</legend>
            <p class="text-danger font-weight-bold">
              Nombre:
              <label
                for="nombreArrendatario"
                class="text-dark font-weight-bold"
                >{{ arrendatario_info.nombre }}</label
              >
            </p>
            <p class="text-danger font-weight-bold">
              Apellidos:
              <label
                for="apellidosArrendatario"
                class="text-dark font-weight-bold"
              >
                {{ arrendatario_info.apellidos }}</label
              >
            </p>
            <p for="dniArrendatario" class="text-danger font-weight-bold">
              DNI:
              <label for="dniArrendatario" class="text-dark font-weight-bold">
                {{ arrendatario_info.documento_identidad }}</label
              >
            </p>
          </fieldset>

          <div id="detallePagos" v-if="pago_alquiler_id !== 'default'">
            <p class="text-danger font-weight-bold">
              Importe total a pagar:
              <label for="importeAdeudado" class="text-dark font-weight-bold">{{
                pagos_realizados.importe_adeudado
              }}</label>
            </p>
            <p class="text-danger font-weight-bold">
              Importe abonado:
              <label
                for="pagoTotalEfectuado"
                class="text-dark font-weight-bold"
                >{{ pagos_realizados.pago_efectuado }}</label
              >
            </p>
            <p class="text-danger font-weight-bold">
              Importe pendiente:
              <label for="importeRestante" class="text-dark font-weight-bold">{{
                pagos_realizados.importe_restante
              }}</label>
            </p>
          </div>

          <label for="arrendatario" class="text-dark font-weight-bold"
            >Arrendatario</label
          >

          <select
            id="arrendatarios"
            class="form-control text-dark"
            v-model="arrendatario_info"
            v-on:change="getPagosAlquiler()"
            :class="validar_datos.arrendatario_info.input_class"
          >
            <option value="default">Seleccionar arrendatario</option>
            <option
              v-for="arrendatario in listado_arrendatarios"
              v-bind:key="arrendatario"
              :value="arrendatario"
            >
              {{ arrendatario.documento_identidad }}
            </option>
          </select>

          <div
            :class="validar_datos.arrendatario_info.div_class"
            v-html="validar_datos.arrendatario_info.msg"
          ></div>

          <div id="infoPago" v-if="arrendatario_info !== 'default'">
            <label for="pago" class="text-dark font-weight-bold">Pago</label>

            <select
              id="pagos"
              class="form-control text-dark"
              v-model="pago_alquiler_id"
              v-on:change="getPagosRealizados()"
              v-if="pagos_alquiler.length > 0"
              :class="validar_datos.pago_alquiler_id.input_class"
            >
              <option value="default">Seleccionar pago</option>
              <option
                v-for="pago in pagos_alquiler"
                v-bind:key="pago"
                :value="pago.id"
              >
                {{ pago.id }} {{ pago.concepto }}
              </option>
            </select>

            <p v-else for="noExistenPagos" class="text-danger font-weight-bold">
              El arrendatario no tiene ningún pago
            </p>
            <div
              :class="validar_datos.pago_alquiler_id.div_class"
              v-html="validar_datos.pago_alquiler_id.msg"
            ></div>
          </div>

          <div
            id="formularioRealizarPago"
            v-if="pago_alquiler_id !== 'default'"
          >
            <label for="importe" class="text-dark font-weight-bold"
              >Importe</label
            >

            <input
              type="text"
              class="form-control text-dark"
              name="importe"
              v-model="importe_entregado"
              :class="validar_datos.importe_entregado.input_class"
            />

            <div
              :class="validar_datos.importe_entregado.div_class"
              v-html="validar_datos.importe_entregado.msg"
            ></div>

            <label for="fecha" class="text-dark font-weight-bold">Fecha</label>

            <!--Utilización de vue datepicker -->
            <datepicker
              v-model:value="fecha"
              v-on:clear="
                fecha = '';
                validarDatos();
              "
              v-on:close="validarDatos()"
              :editable="true"
              :input-class="validar_datos.fecha.input_class"
              :format="'DD-MM-YYYY'"
              :value-type="'format'"
              :lang="'es.es'"
            ></datepicker>

            <div
              :class="validar_datos.fecha.div_class"
              v-html="validar_datos.fecha.msg"
            ></div>

            <label for="metodo_pago" class="text-dark font-weight-bold"
              >Método de pago</label
            >

            <select
              id="metodoPago"
              class="form-control text-dark"
              v-model="metodo_pago"
            >
              <option value="default">Seleccionar método de pago</option>
              <option
                v-for="metodo in listado_metodos_pago"
                v-bind:key="metodo"
                :value="metodo"
              >
                {{ metodo }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="crearPagoRealizado"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearPagoRealizado()"
          >
            Crear Operación
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
$(document).ready(function () {
  // Evita que el modal se cierre cuando hagamos click fuera de él.
  $("#crearPagoRealizado").modal({
    show: false,
    backdrop: "static",
  });
});

import moment from "moment";

export default {
  props: ["listado_arrendatarios", "listado_metodos_pago"],
  data: function () {
    return {
      // Datos del formulario.
      arrendatario_info: "default", // Guardamos todos los datos del arrendatario que esté seleccionado en el select para mostrar su información.
      // Campos tabla 'pagos_realizados'
      importe_entregado: "",
      fecha: "",
      metodo_pago: "default",
      pago_alquiler_id: "default", // Contiene el ID del pago que se haya seleccionado en el select #pagos.
      ////////////////////////////////
      nuevoPagoRealizado: {}, // Objeto con todos los datos para agregar finalmente a la bd.
      // Variables para el select de #pagos.
      pagos_alquiler: [], // Pagos de la tabla pago_alquiler que se mostrarán en el select #pagos cuando se seleccione un arrendatario.
      pagos_realizados: "", // Contiene la información sobre la cantidad adeudada, transacciones realizadas y el importe restante del id_pago seleccionado en el select #pagos.
      resultado: {
        // Almacenar el resultado de las operaciones entre el cliente - servidor.
        success: "",
        error: [],
        select: {
          fila_editada: 0, // ID del row que ha sido editada para poderla resaltar.
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        arrendatario_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        pago_alquiler_id: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        importe_entregado: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        fecha: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "form-control",
        },
      },
    };
  },
  methods: {
    crearPagoRealizado() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;
        // Almacenar el registro en la bd.

        this.nuevoPagoRealizado = {
          importe_entregado: this.importe_entregado,
          fecha: this.fecha,
          metodo_pago: this.metodo_pago,
          pago_alquiler_id: this.pago_alquiler_id,
        };

        /* 
          Comprobar si se ha escogido o no un método de pago en el select.

          Esta comprobación se realiza ya que el campo 'metodo_pago' puede ser nulo, y en ese caso,
          tenemos que establecer a null el valor.

          Si no lo hacemos, recibiremos un error de mysql ya que 'default' (opción seleccionada por defecto) no existe
          en la lista de enum.
        
        */

        if (this.nuevoPagoRealizado.metodo_pago === "default") {
          this.nuevoPagoRealizado.metodo_pago = null;
        }

        let pagosRealizadosStore = "pagosRealizadosAlquiler";
        axios
          .post(pagosRealizadosStore, this.nuevoPagoRealizado)
          .then((response) => {
            this.resultado.select.fila_editada = response.data.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })
          .finally(() => {
            $("#crearPagoRealizado").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      }
    },

    /* Método que permite obtener los pagos que tenga pendientes el arrendatario de un alquiler */
    getPagosAlquiler() {
      this.pago_alquiler_id = "default"; // Para que cuando seleccionemos un arrendatario el select con los id's de pagos se establezca en default.
      this.metodo_pago = "default";

      let urlPagosAlquiler =
        "pagoAlquiler/" + this.arrendatario_info.documento_identidad;
      axios
        .get(urlPagosAlquiler)
        .then((response) => {
          // Comprobar el código de la respuesta del controlador.
          if (response.status === 200) {
            this.pagos_alquiler = response.data.pagos_alquiler;
          }
        })
        .catch((error) => {
          this.pagos_alquiler = "";
        });
    },
    /* Método que devuelve un registro con la siguiente información sobre las transacciones
        realizadas por el alumno sobre un determinado pago.
        1) Importe adeudado.
        2) SUMA de todos los pagos efectuados.
        3) Importe restante (importe adeudado - SUMA pagos efectuados).
    */
    getPagosRealizados() {
      let urlPagosRealizados =
        "pagosRealizadosAlquiler/" +
        this.arrendatario_info.documento_identidad +
        "/" +
        this.pago_alquiler_id;
      axios
        .get(urlPagosRealizados)
        .then((response) => {
          // Comprobar el código de la respuesta del controlador.
          if (response.status === 200) {
            this.enum_metodos_pago =
              response.data.pagos_realizados.metodos_pago;
            this.pagos_realizados = {
              importe_adeudado: response.data.pagos_realizados.importe_adeudado,
              pago_efectuado: response.data.pagos_realizados.pago_efectuado,
              importe_restante: response.data.pagos_realizados.importe_restante,
            };
          }
        })
        .catch((error) => {
          this.pagos_realizados = "";
        });
    },
    validarDatos() {
      let datosValidos = false;

      // ARRENDATARIO

      if (this.arrendatario_info === "default") {
        this.validar_datos.arrendatario_info.valid = false;
        this.validar_datos.arrendatario_info.msg =
          "Debe seleccionarse un <strong>Arrendatario</strong>.";
        this.validar_datos.arrendatario_info.input_class = "is-invalid";
        this.validar_datos.arrendatario_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.arrendatario_info.valid = true;
        this.validar_datos.arrendatario_info.msg =
          "El <strong>Arrendatario</strong> es válido.";
        this.validar_datos.arrendatario_info.input_class = "is-valid";
        this.validar_datos.arrendatario_info.div_class = "valid-feedback";
      }

      // PAGO

      if (this.pago_alquiler_id === "default") {
        this.validar_datos.pago_alquiler_id.valid = false;
        this.validar_datos.pago_alquiler_id.msg =
          "Debe seleccionarse un <strong>Pago</strong>.";
        this.validar_datos.pago_alquiler_id.input_class = "is-invalid";
        this.validar_datos.pago_alquiler_id.div_class = "invalid-feedback";
      } else {
        this.validar_datos.pago_alquiler_id.valid = true;
        this.validar_datos.pago_alquiler_id.msg =
          "El <strong>Pago</strong> es válido.";
        this.validar_datos.pago_alquiler_id.input_class = "is-valid";
        this.validar_datos.pago_alquiler_id.div_class = "valid-feedback";
      }

      // IMPORTE ENTREGADO

      if (this.importe_entregado != null) {
        if (this.importe_entregado.length > 0) {
          // Comprobar formato regex. Se permite el uso exclusivo de números y punto.
          if (/^[0-9]+(\.[0-9]+)?$/.test(this.importe_entregado)) {
            // Comprobar rango.
            if (
              this.importe_entregado >= -9999.99 &&
              this.importe_entregado <= 9999.99
            ) {
              this.validar_datos.importe_entregado.valid = true;
              this.validar_datos.importe_entregado.msg =
                "El <strong>Importe</strong> es válido.";
              this.validar_datos.importe_entregado.input_class = "is-valid";
              this.validar_datos.importe_entregado.div_class = "valid-feedback";
            } else {
              this.validar_datos.importe_entregado.valid = false;
              this.validar_datos.importe_entregado.msg =
                "Debe escribirse un <strong>Importe</strong> dentro de un rango válido.";
              this.validar_datos.importe_entregado.input_class = "is-invalid";
              this.validar_datos.importe_entregado.div_class =
                "invalid-feedback";
            }
          } else {
            this.validar_datos.importe_entregado.valid = false;
            this.validar_datos.importe_entregado.msg =
              "Debe escribirse un <strong>Importe</strong> válido.";
            this.validar_datos.importe_entregado.input_class = "is-invalid";
            this.validar_datos.importe_entregado.div_class = "invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.importe_entregado = "";
          this.validar_datos.importe_entregado.valid = true;
          this.validar_datos.importe_entregado.msg = "";
          this.validar_datos.importe_entregado.input_class = "";
          this.validar_datos.importe_entregado.div_class = "";
        }
      }

      // FECHA

      if (this.fecha !== null) {
        if (this.fecha.length > 0) {
          if (moment(this.fecha, "DD-MM-YYYY", true).isValid()) {
            this.validar_datos.fecha.valid = true;
            this.validar_datos.fecha.msg =
              "La <strong>Fecha</strong> es válida.";
            this.validar_datos.fecha.input_class =
              "form-control text-dark is-valid";
            this.validar_datos.fecha.div_class = "d-block valid-feedback";
          } else {
            this.validar_datos.fecha.valid = false;
            this.validar_datos.fecha.msg =
              "La <strong>Fecha</strong> no es válida.";
            this.validar_datos.fecha.input_class =
              "form-control text-dark is-invalid";
            this.validar_datos.fecha.div_class = "d-block invalid-feedback";
          }
        } else {
          // En caso de volver a ser nulo, retirar clases de estilo para validar.
          this.fecha = "";
          this.validar_datos.fecha.valid = true;
          this.validar_datos.fecha.msg = "";
          this.validar_datos.fecha.input_class = "form-control";
          this.validar_datos.fecha.div_class = "";
        }
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.arrendatario_info.valid &&
        this.validar_datos.pago_alquiler_id.valid &&
        this.validar_datos.importe_entregado.valid &&
        this.validar_datos.fecha.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },

    limpiarFormulario() {
      this.arrendatario_info = "default";
      this.pago_alquiler_id = "default";
      this.importe_entregado = null;
      this.fecha = null;
      this.metodo_pago = "default";

      this.nuevoPagoRealizado = {};

      this.pagos_alquiler = [];
      this.pagos_realizados = "";
      this.resultado = {
        success: "",
        error: [],
        select: {
          fila_editada: 0, // ID del row que ha sido editada para poderla resaltar.
        },
      };

      // VALIDACIONES
      this.validar_datos = {
        arrendatario_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        pago_alquiler_id: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        importe_entregado: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        fecha: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "form-control",
        },
      };
    },
  },
};
</script>