<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el registro-->
  <div class="modal" id="crearPagoAlquiler">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="createPagoAlquiler">Agregar pago</h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <fieldset v-if="arrendatario_info !== 'default'">
            <legend>Información del arrendatario</legend>
            <p class="text-danger font-weight-bold">
              Nombre:
              <label
                for="nombreArrendatario"
                class="text-dark font-weight-bold"
                >{{ arrendatario_info.nombre }}</label
              >
            </p>
            <p class="text-danger font-weight-bold">
              Apellidos:
              <label
                for="apellidosArrendatario"
                class="text-dark font-weight-bold"
              >
                {{ arrendatario_info.apellidos }}</label
              >
            </p>
            <p for="arrendatarioDNI" class="text-danger font-weight-bold">
              DNI:
              <label for="dniArrendatario" class="text-dark font-weight-bold">
                {{ arrendatario_info.documento_identidad }}</label
              >
            </p>
          </fieldset>

          <label for="arrendatario" class="text-dark font-weight-bold"
            >Arrendatario</label
          >
          <!--select para mostrar los arrendatarios ya creados-->
          <select
            id="arrendatarios"
            class="form-control text-dark"
            v-model="arrendatario_info"
            :class="validar_datos.arrendatario_info.input_class"
          >
            <option value="default">Seleccionar arrendatario</option>
            <option
              v-for="arrendatario in listado_arrendatarios"
              v-bind:key="arrendatario"
              :value="arrendatario"
            >
              {{ arrendatario.documento_identidad }}
            </option>
          </select>

          <div
            :class="validar_datos.arrendatario_info.div_class"
            v-html="validar_datos.arrendatario_info.msg"
          ></div>

          <label for="importe_total" class="text-dark font-weight-bold"
            >Importe total</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="importe_total"
            v-model="importe_total"
            :class="validar_datos.importe_total.input_class"
          />

          <div
            :class="validar_datos.importe_total.div_class"
            v-html="validar_datos.importe_total.msg"
          ></div>

          <label for="concepto" class="text-dark font-weight-bold"
            >Concepto</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="concepto"
            v-model="concepto"
            :class="validar_datos.concepto.input_class"
          />
          <div
            :class="validar_datos.concepto.div_class"
            v-html="validar_datos.concepto.msg"
          ></div>
        </div>
        <div class="d-flex p-3 justify-content-end">
          <!-- v-on:click asigna un evento que al pulsar llame a la funcion crearEdicion-->
          <button
            type="button"
            name="crearPagoAlquiler"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearPagoAlquiler()"
          >
            Crear pago
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
$(document).ready(function () {
  // Evita que el modal se cierre cuando hagamos click fuera de él.
  $("#crearPagoAlquiler").modal({
    show: false,
    backdrop: "static",
  });
});

export default {
  //propiedades donde tenemos acceso a las variables del componente padre
  props: ["listado_arrendatarios"],
  data: function () {
    return {
      arrendatario_info: "default", // Guardamos los datos del alumno seleccionado en el select.
      importe_total: "",
      concepto: "",
      nuevoPagoAlquiler: {},
      resultado: {
        // Almacenar el resultado de las operaciones entre el cliente - servidor.
        success: "",
        error: "",
        select: {
          fila_editada: 0, // ID del row que ha sido editada para poderla resaltar.
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        arrendatario_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        importe_total: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        concepto: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    //funcion para crear registros en la base de datos
    crearPagoAlquiler() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        this.nuevoPagoAlquiler = {
          arrendatario_documento_identidad:
            this.arrendatario_info.documento_identidad,
          importe_total: this.importe_total,
          concepto: this.concepto,
        };

        let pagoAlquilerStore = "pagoAlquiler";
        axios
          .post(pagoAlquilerStore, this.nuevoPagoAlquiler)
          .then((response) => {
             this.resultado.select.fila_editada = response.data.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })
          //cuando todo se haya completado muestra notificacion en el padre y borra el contenido de las variables al guardar la informaión
          .finally(() => {
            $("#crearPagoAlquiler").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      }
    },

    validarDatos() {
      let datosValidos = false;

      // ARRENDATARIO DOCUMENTO IDENTIDAD

      if (this.arrendatario_info === "default") {
        this.validar_datos.arrendatario_info.valid = false;
        this.validar_datos.arrendatario_info.msg =
          "Debe seleccionarse un <strong>Arrendatario</strong>.";
        this.validar_datos.arrendatario_info.input_class = "is-invalid";
        this.validar_datos.arrendatario_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.arrendatario_info.valid = true;
        this.validar_datos.arrendatario_info.msg =
          "El <strong>Arrendatario</strong> es válido.";
        this.validar_datos.arrendatario_info.input_class = "is-valid";
        this.validar_datos.arrendatario_info.div_class = "valid-feedback";
      }

      // IMPORTE TOTAL

      if (this.importe_total.length > 0) {
        // Comprobar formato regex. Se permite el uso exclusivo de números y punto.
        if (/^[0-9]+(\.[0-9]+)?$/.test(this.importe_total)) {
          // Comprobar rango.
          if (this.importe_total >= -9999.99 && this.importe_total <= 9999.99) {
            this.validar_datos.importe_total.valid = true;
            this.validar_datos.importe_total.msg =
              "El <strong>Importe total</strong> es válido.";
            this.validar_datos.importe_total.input_class = "is-valid";
            this.validar_datos.importe_total.div_class = "valid-feedback";
          } else {
            this.validar_datos.importe_total.valid = false;
            this.validar_datos.importe_total.msg =
              "Debe escribirse un <strong>Importe total</strong> dentro de un rango válido.";
            this.validar_datos.importe_total.input_class = "is-invalid";
            this.validar_datos.importe_total.div_class = "invalid-feedback";
          }
        } else {
          this.validar_datos.importe_total.valid = false;
          this.validar_datos.importe_total.msg =
            "Debe escribirse un <strong>Importe total</strong> válido.";
          this.validar_datos.importe_total.input_class = "is-invalid";
          this.validar_datos.importe_total.div_class = "invalid-feedback";
        }
      } else {
        // En caso de volver a ser nulo, retirar clases de estilo para validar.
        this.importe_total = "";
        this.validar_datos.importe_total.valid = true;
        this.validar_datos.importe_total.msg = "";
        this.validar_datos.importe_total.input_class = "";
        this.validar_datos.importe_total.div_class = "";
      }

      // CONCEPTO

      if (this.concepto !== null) {
        if (this.concepto.length > 0) {
          // Comprobar la longitud.

          if (this.concepto.length > 0 && this.concepto.length <= 255) {
            this.validar_datos.concepto.valid = true;
            this.validar_datos.concepto.msg =
              "El <strong>Concepto</strong> es válido.";
            this.validar_datos.concepto.input_class = "is-valid";
            this.validar_datos.concepto.div_class = "valid-feedback";
          } else {
            this.validar_datos.concepto.valid = false;
            this.validar_datos.concepto.msg =
              "El <strong>Concepto</strong> no puede superar los 255 caracteres.";
            this.validar_datos.concepto.input_class = "is-invalid";
            this.validar_datos.concepto.div_class = "invalid-feedback";
          }
        } else {
          this.validar_datos.concepto.valid = false;
          this.validar_datos.concepto.msg =
            "El <strong>Concepto</strong> es obligatorio.";
          this.validar_datos.concepto.input_class = "is-invalid";
          this.validar_datos.concepto.div_class = "invalid-feedback";
        }
      } else {
        this.validar_datos.concepto.valid = false;
        this.validar_datos.concepto.msg =
          "El <strong>Concepto</strong> es obligatorio.";
        this.validar_datos.concepto.input_class = "is-invalid";
        this.validar_datos.concepto.div_class = "invalid-feedback";
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.arrendatario_info.valid &&
        this.validar_datos.importe_total.valid &&
        this.validar_datos.concepto.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },

    limpiarFormulario() {
      this.arrendatario_info = "default";
      this.importe_total = null;
      this.concepto = "";
      this.nuevoPagoAlquiler = {};

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.validar_datos = {
        arrendatario_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        importe_total: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        concepto: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      };
    },
  },
};
</script>