<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el regsitro-->
  <div class="modal" id="crearAula">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="crearAulaLabel">Crear Aula</h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <label for="nombre" class="text-dark font-weight-bold">Nombre</label>
          <!-- v-model almacenamos en la variable los datos introducidos en el input -->
          <input
            type="text"
            class="form-control text-dark"
            name="nombre"
            v-model="nuevoAula.nombre"
            :class="validar_datos.nombre.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.nombre.div_class"
            v-html="validar_datos.nombre.msg"
          ></div>

          <label for="fecha_inicio" class="text-dark font-weight-bold"
            >Fecha de Inicio Alquiler</label
          >

          <!--Utilización de vue datepicker -->
          <datepicker
            v-model:value="nuevoAula.fecha_inicio"
            v-on:clear="
              nuevoAula.fecha_inicio = '';
              validarDatos();
            "
            v-on:close="validarDatos()"
            :editable="true"
            :input-class="validar_datos.fecha_inicio.input_class"
            :format="'DD-MM-YYYY'"
            :value-type="'format'"
            :lang="'es.es'"
          ></datepicker>

          <div
            :class="validar_datos.fecha_inicio.div_class"
            v-html="validar_datos.fecha_inicio.msg"
          ></div>

          <label for="fecha_fin" class="text-dark font-weight-bold"
            >Fecha Fin Alquiler</label
          >

          <!--Utilización de vue datepicker -->
          <datepicker
            v-model:value="nuevoAula.fecha_fin"
            v-on:clear="
              nuevoAula.fecha_fin = '';
              validarDatos();
            "
            v-on:close="validarDatos()"
            :editable="true"
            :input-class="validar_datos.fecha_fin.input_class"
            :format="'DD-MM-YYYY'"
            :value-type="'format'"
            :lang="'es.es'"
          ></datepicker>

          <div
            :class="validar_datos.fecha_fin.div_class"
            v-html="validar_datos.fecha_fin.msg"
          ></div>

          <label for="precio" class="text-dark font-weight-bold"
            >Precio del Alquiler</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="precio"
            v-model="nuevoAula.precio_alquiler"
            :class="validar_datos.precio_alquiler.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.precio_alquiler.div_class"
            v-html="validar_datos.precio_alquiler.msg"
          ></div>

          <label for="horas" class="text-dark font-weight-bold"
            >Horas de Alquiler</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="horas"
            v-model="nuevoAula.horas_totales"
            :class="validar_datos.horas_totales.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.horas_totales.div_class"
            v-html="validar_datos.horas_totales.msg"
          ></div>
        </div>
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="crearAula"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearAula()"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#crearAula").modal({
    show: false,
    backdrop: "static",
  });
});

import moment from "moment";

export default {
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      nuevoAula: {
        nombre: null,
        fecha_inicio: null,
        fecha_fin: null,
        precio_alquiler: "",
        horas_totales: "",
      },
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        nombre: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        fecha_inicio: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "form-control",
        },
        fecha_fin: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "form-control",
        },
        precio_alquiler: {
          msg: "",
          valid: true, // inicializado en true cuando sean campos no obligatorios.
          div_class: "",
          input_class: "",
        },
        horas_totales: {
          msg: "",
          valid: true, // inicializado en true cuando sean campos no obligatorios.
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    //funcion para crear registros en la base de datos
    crearAula() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        let aulaStore = "aulas"; //almaceno en la variable la ruta que accede al metodo store del controlador
        axios
          .post(aulaStore, this.nuevoAula)
          .then((response) => {
            this.resultado.select.fila_editada = response.data.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })
          //cuando todo se haya completado muestra notificacion en el padre
          .finally(() => {
            $("#crearAula").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      } else {
        this.mostrarErrores();
      }
    },

    validarDatos() {
      let datosValidos = false;

      // NOMBRE

      if (this.nuevoAula.nombre !== null) {
        if (this.nuevoAula.nombre.length === 0) {
          this.validar_datos.nombre.valid = false;
          this.validar_datos.nombre.msg =
            "El <strong>nombre</strong> es obligatorio.";
          this.validar_datos.nombre.input_class = "is-invalid";
          this.validar_datos.nombre.div_class = "invalid-feedback";
        } else {
          // Comprobar longitud
          if (
            this.nuevoAula.nombre.length > 0 &&
            this.nuevoAula.nombre.length <= 45
          ) {
            this.validar_datos.nombre.valid = true;
            this.validar_datos.nombre.msg =
              "El <strong>nombre</strong> es válido";
            this.validar_datos.nombre.input_class = "is-valid";
            this.validar_datos.nombre.div_class = "valid-feedback";
          } else {
            this.validar_datos.nombre.valid = false;
            this.validar_datos.nombre.msg =
              "El <strong>nombre</strong> no puede superar los 45 caracteres";
            this.validar_datos.nombre.input_class = "is-invalid";
            this.validar_datos.nombre.div_class = "invalid-feedback";
          }
        }
      }

      // FECHA INICIO

      // Comprobar si no es nulo
      if (this.nuevoAula.fecha_inicio !== null) {
        if (this.nuevoAula.fecha_inicio.length === 0) {
          this.validar_datos.fecha_inicio.valid = false;
          this.validar_datos.fecha_inicio.msg =
            "La <strong>Fecha de inicio</strong> es obligatoria.";
          this.validar_datos.fecha_inicio.input_class =
            "form-control text-dark is-invalid";
          this.validar_datos.fecha_inicio.div_class =
            "d-block invalid-feedback";
        } else {
          // Validar formato fecha d-m-y.

          if (
            moment(this.nuevoAula.fecha_inicio, "DD-MM-YYYY", true).isValid()
          ) {
            this.validar_datos.fecha_inicio.valid = true;
            this.validar_datos.fecha_inicio.msg =
              "La <strong>Fecha de inicio</strong> es válida.";
            this.validar_datos.fecha_inicio.input_class =
              "form-control text-dark is-valid";
            this.validar_datos.fecha_inicio.div_class =
              "d-block valid-feedback";
          } else {
            this.validar_datos.fecha_inicio.valid = false;
            this.validar_datos.fecha_inicio.msg =
              "La <strong>Fecha de inicio</strong> no tiene un formato válido.";
            this.validar_datos.fecha_inicio.input_class =
              "form-control text-dark is-invalid";
            this.validar_datos.fecha_inicio.div_class =
              "d-block invalid-feedback";
          }
        }
      }

      // FECHA FIN

      if (this.nuevoAula.fecha_fin !== null) {
        if (this.nuevoAula.fecha_fin.length === 0) {
          this.validar_datos.fecha_fin.valid = false;
          this.validar_datos.fecha_fin.msg =
            "La <strong>Fecha de fin</strong> es obligatoria.";
          this.validar_datos.fecha_fin.input_class =
            "form-control text-dark is-invalid";
          this.validar_datos.fecha_fin.div_class = "d-block invalid-feedback";
        } else {
          // Validar formato fecha d-m-y.

          if (moment(this.nuevoAula.fecha_fin, "DD-MM-YYYY", true).isValid()) {
            this.validar_datos.fecha_fin.valid = true;
            this.validar_datos.fecha_fin.msg =
              "La <strong>Fecha de fin</strong> es válida.";
            this.validar_datos.fecha_fin.input_class =
              "form-control text-dark is-valid";
            this.validar_datos.fecha_fin.div_class = "d-block valid-feedback";
          } else {
            this.validar_datos.fecha_fin.valid = false;
            this.validar_datos.fecha_fin.msg =
              "La <strong>Fecha de fin</strong> no tiene un formato válido.";
            this.validar_datos.fecha_fin.input_class =
              "form-control text-dark is-invalid";
            this.validar_datos.fecha_fin.div_class = "d-block invalid-feedback";
          }
        }
      }

      // PRECIO ALQUILER.

      // Validar precio_alquiler solo números y puntos && rango de valor.
      if (this.nuevoAula.precio_alquiler.length > 0) {
        if (
          /^[0-9\.]+$/.test(this.nuevoAula.precio_alquiler) &&
          this.nuevoAula.precio_alquiler >= -9999.99 &&
          this.nuevoAula.precio_alquiler <= 9999.99
        ) {
          this.validar_datos.precio_alquiler.valid = true;
          this.validar_datos.precio_alquiler.msg =
            "El <strong>Precio del alquiler</strong> es válido.";
          this.validar_datos.precio_alquiler.input_class = "is-valid";
          this.validar_datos.precio_alquiler.div_class = "valid-feedback";
        } else {
          this.validar_datos.precio_alquiler.valid = false;
          this.validar_datos.precio_alquiler.msg =
            "El <strong>Precio del alquiler</strong> no tiene un formato válido.";
          this.validar_datos.precio_alquiler.input_class = "is-invalid";
          this.validar_datos.precio_alquiler.div_class = "invalid-feedback";
        }
      } else {
        // En caso de volver a ser nulo, retirar clases de estilo para validar.
        this.nuevoAula.precio_alquiler = "";
        this.validar_datos.precio_alquiler.valid = true;
        this.validar_datos.precio_alquiler.msg = "";
        this.validar_datos.precio_alquiler.input_class = "";
        this.validar_datos.precio_alquiler.div_class = "";
      }

      // HORAS.

      if (this.nuevoAula.horas_totales.length > 0) {
        if (/^(\d{0,10})$/.test(this.nuevoAula.horas_totales)) {
          this.validar_datos.horas_totales.valid = true;
          this.validar_datos.horas_totales.msg =
            "<strong>Horas de Alquiler</strong> es válido.";
          this.validar_datos.horas_totales.input_class = "is-valid";
          this.validar_datos.horas_totales.div_class = "valid-feedback";
        } else {
          this.validar_datos.horas_totales.valid = false;
          this.validar_datos.horas_totales.msg =
            "<strong>Horas de Alquiler</strong> no tiene un formato válido.";
          this.validar_datos.horas_totales.input_class = "is-invalid";
          this.validar_datos.horas_totales.div_class = "invalid-feedback";
        }
      } else {
        // En caso de volver a ser nulo, retirar clases de estilo para validar.
        this.nuevoAula.horas_totales = "";
        this.validar_datos.horas_totales.valid = true;
        this.validar_datos.horas_totales.msg = "";
        this.validar_datos.horas_totales.input_class = "";
        this.validar_datos.horas_totales.div_class = "";
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.nombre.valid &&
        this.validar_datos.fecha_inicio.valid &&
        this.validar_datos.fecha_fin.valid &&
        this.validar_datos.precio_alquiler.valid &&
        this.validar_datos.horas_totales.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    /* mostrarErrores() -> Informa al usuario de los campos erróneos cuando trata de enviar los datos al servidor sin 
      haber rellenado previamente todos los campos del formulario. */

    mostrarErrores() {
      this.success = "";

      // Nombre.
      if (this.nuevoAula.nombre === null) {
        this.validar_datos.nombre.valid = false;
        this.validar_datos.nombre.msg =
          "El <strong>Nombre</strong> es obligatorio.";
        this.validar_datos.nombre.input_class = "is-invalid";
        this.validar_datos.nombre.div_class = "invalid-feedback";
      }

      // Fecha inicio alquiler.
      if (this.nuevoAula.fecha_inicio === null) {
        this.validar_datos.fecha_inicio.valid = false;
        this.validar_datos.fecha_inicio.msg =
          "La <strong>Fecha de Inicio Alquiler</strong> es obligatoria.";
        this.validar_datos.fecha_inicio.input_class = "is-invalid";
        this.validar_datos.fecha_inicio.div_class = "invalid-feedback";
      }

      // Fecha fin alquiler.
      if (this.nuevoAula.fecha_fin === null) {
        this.validar_datos.fecha_fin.valid = false;
        this.validar_datos.fecha_fin.msg =
          "La <strong>Fecha Fin Alquiler</strong> es obligatoria.";
        this.validar_datos.fecha_fin.input_class = "is-invalid";
        this.validar_datos.fecha_fin.div_class = "invalid-feedback";
      }
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },
    limpiarFormulario() {
      this.nuevoAula = {
        nombre: null,
        fecha_inicio: null,
        fecha_fin: null,
        precio_alquiler: "",
        horas_totales: "",
      };

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.validar_datos = {
        nombre: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        fecha_inicio: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "form-control",
        },
        fecha_fin: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "form-control",
        },
        precio_alquiler: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        horas_totales: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
      };
    },
  },
};
</script>