require('./bootstrap');

// Importar JQuery para poderlo usar en el datatable.
import $ from 'jquery';
window.$ = window.jQuery = $;

// Botones para datatables de exportar en pdf, excel, csv.

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import JSZip from "jszip";
window.JSZip = JSZip;

//diseños css de datatables
require('datatables.net-bs4/css/dataTables.bootstrap4.css');
require('datatables.net-buttons-bs4/css/buttons.bootstrap4.css');

//dependencias de datatable para su correcto funcionamiento
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';

// Datetime css + js.

require('datatables.net-datetime/css/datetime.css');
require('datatables.net-searchbuilder/css/searchBuilder.dataTables.css');
import 'datatables.net-datetime';

//Select css + js
require('datatables.net-select-dt/css/select.dataTables.css');
import 'datatables.net-select-dt/js/select.dataTables.js';
require('datatables.net-plugins/api/row().show()');
require('datatables.net-dt');

// SearchBuilder css + js.

require('datatables.net-searchbuilder-bs4/css/searchBuilder.bootstrap4.css');
import 'datatables.net-searchbuilder-bs4/js/searchBuilder.bootstrap4.js';

// Responsive css + js.
require('datatables.net-responsive-dt/css/responsive.dataTables.css');
require('datatables.net-responsive-dt/css/responsive.dataTables.min.css');
import 'datatables.net-responsive-dt/js/responsive.dataTables.js';
import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js';
import 'datatables.net-responsive-dt';

//DATEPICKER
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/es.es'; //IDIOMA ESPAÑOL


//creamos nueva instancia de la aplicacion vue con la funcion createApp
import { createApp } from 'vue';


//Curso
// Importación de componentes padre.
import Curso from './components/cursos/Curso.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#curso') != null) {


    // Comenzar a montar los componentes padres.
    const curso = createApp(Curso);

    // Agregar sub-componentes al componente padre (Curso).
    curso.component('CursoCrear', require('./components/cursos/CursoCrear.vue').default);
    curso.component('CursoEditar', require('./components/cursos/CursoEditar.vue').default);

    //Montar componentes.
    curso.mount('#curso');
}


//EdicionCurso
// Importación de componentes padre.
import EdicionCurso from './components/edicionesCursos/EdicionCurso.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#edicion') != null) {


    // Comenzar a montar los componentes padres.
    const edicion = createApp(EdicionCurso);


    // Agregar sub-componentes al componente padre (Edicion).
    edicion.component('EdicionCursoCrear', require('./components/edicionesCursos/EdicionCursoCrear.vue').default);
    edicion.component('EdicionCursoEditar', require('./components/edicionesCursos/EdicionCursoEditar.vue').default);
    edicion.component('datepicker', DatePicker);

    //Montar componentes.
    edicion.mount('#edicion');
}


//Alumno
// Importación de componentes padre.
import Alumno from './components/alumnos/Alumno.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#alumno') != null) {


    // Comenzar a montar los componentes padres.
    const alumno = createApp(Alumno);

    // Agregar sub-componentes al componente padre (Alumno).
    alumno.component('AlumnoCrear', require('./components/alumnos/AlumnoCrear.vue').default);
    alumno.component('AlumnoEditar', require('./components/alumnos/AlumnoEditar.vue').default);
    alumno.component('datepicker', DatePicker);

    //Montar componentes.
    alumno.mount('#alumno');
}

//AlumnoPorCurso
// Importación de componentes padre.
import AlumnosPorCurso from './components/alumnosPorCurso/AlumnosPorCurso.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#alumnosPorCurso') != null) {


    // Comenzar a montar los componentes padres.
    const alumnosPorCurso = createApp(AlumnosPorCurso);

    // Agregar sub-componentes al componente padre (AlumnosPorCurso).
    alumnosPorCurso.component('AlumnosPorCursoCrear', require('./components/alumnosPorCurso/AlumnosPorCursoCrear.vue').default);
    alumnosPorCurso.component('AlumnosPorCursoEditar', require('./components/alumnosPorCurso/AlumnosPorCursoEditar.vue').default);

    //Montar componentes.
    alumnosPorCurso.mount('#alumnosPorCurso');
}

//Profesor
// Importación de componentes padre.
import Profesor from './components/profesores/Profesor.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#profesor') != null) {


    // Comenzar a montar los componentes padres.
    const profesor = createApp(Profesor);

    // Agregar sub-componentes al componente padre (AlumnosPorCurso).
    profesor.component('ProfesorCrear', require('./components/profesores/ProfesorCrear.vue').default);
    profesor.component('ProfesorEditar', require('./components/profesores/ProfesorEditar.vue').default);

    //Montar componentes.
    profesor.mount('#profesor');
}


//Aula
// Importación de componentes padre.
import Aula from './components/aulas/Aula.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#aula') != null) {


    // Comenzar a montar los componentes padres.
    const aula = createApp(Aula);

    // Agregar sub-componentes al componente padre (Aula).
    aula.component('AulaCrear', require('./components/aulas/AulaCrear.vue').default);
    aula.component('AulaEditar', require('./components/aulas/AulaEditar.vue').default);
    aula.component('datepicker', DatePicker);

    //Montar componentes.
    aula.mount('#aula');
}

//Arrendatario
// Importación de componentes padre.
import Arrendatario from './components/arrendatarios/Arrendatario.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#arrendatario') != null) {


    // Comenzar a montar los componentes padres.
    const arrendatario = createApp(Arrendatario);

    // Agregar sub-componentes al componente padre (Arrendatario).
    arrendatario.component('ArrendatarioCrear', require('./components/arrendatarios/ArrendatarioCrear.vue').default);
    arrendatario.component('ArrendatarioEditar', require('./components/arrendatarios/ArrendatarioEditar.vue').default);

    //Montar componentes.
    arrendatario.mount('#arrendatario');
}


//ProfesoresPorCurso
// Importación de componentes padre.
import ProfesoresPorCurso from './components/profesoresPorCurso/ProfesoresPorCurso.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#profesoresPorCurso') != null) {


    // Comenzar a montar los componentes padres.
    const profesoresPorCurso = createApp(ProfesoresPorCurso);

    // Agregar sub-componentes al componente padre (ProfesoresPorCurso).
    profesoresPorCurso.component('ProfesoresPorCursoCrear', require('./components/profesoresPorCurso/ProfesoresPorCursoCrear.vue').default);
    profesoresPorCurso.component('ProfesoresPorCursoEditar', require('./components/profesoresPorCurso/ProfesoresPorCursoEditar.vue').default);

    //Montar componentes.
    profesoresPorCurso.mount('#profesoresPorCurso');
}



//ArrendatariosPorAula
// Importación de componentes padre.
import ArrendatariosPorAula from './components/arrendatariosPorAula/ArrendatariosPorAula.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#arrendatariosPorAula') != null) {


    // Comenzar a montar los componentes padres.
    const arrendatariosPorAula = createApp(ArrendatariosPorAula);

    // Agregar sub-componentes al componente padre (ArrendatariosPorAula).
    arrendatariosPorAula.component('ArrendatariosPorAulaCrear', require('./components/arrendatariosPorAula/ArrendatariosPorAulaCrear.vue').default);
    arrendatariosPorAula.component('ArrendatariosPorAulaEditar', require('./components/arrendatariosPorAula/ArrendatariosPorAulaEditar.vue').default);

    //Montar componentes.
    arrendatariosPorAula.mount('#arrendatariosPorAula');
}

//PagoPorAlumno
// Importación de componentes padre.
import PagoPorAlumno from './components/pagosPorAlumnos/PagoPorAlumno.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#pagoPorAlumno') != null) {


    // Comenzar a montar los componentes padres.
    const pagoPorAlumno = createApp(PagoPorAlumno);

    // Agregar sub-componentes al componente padre (PagoPorAlumno).
    pagoPorAlumno.component('PagoPorAlumnoCrear', require('./components/pagosPorAlumnos/PagoPorAlumnoCrear.vue').default);
    pagoPorAlumno.component('PagoPorAlumnoEditar', require('./components/pagosPorAlumnos/PagoPorAlumnoEditar.vue').default);

    //Montar componentes.
    pagoPorAlumno.mount('#pagoPorAlumno');
}


//PagoRealizadosAlumnos
// Importación de componentes padre.
import PagosRealizadosAlumnos from './components/pagosRealizadosAlumnos/PagosRealizadosAlumnos.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#pagosRealizadosAlumnos') != null) {


    // Comenzar a montar los componentes padres.
    const pagosRealizadosAlumnos = createApp(PagosRealizadosAlumnos);

    // Agregar sub-componentes al componente padre (pagosRealizadosAlumnos).
    pagosRealizadosAlumnos.component('PagosRealizadosAlumnosCrear', require('./components/pagosRealizadosAlumnos/PagosRealizadosAlumnosCrear.vue').default);
    pagosRealizadosAlumnos.component('PagosRealizadosAlumnosEditar', require('./components/pagosRealizadosAlumnos/PagosRealizadosAlumnosEditar.vue').default);
    pagosRealizadosAlumnos.component('datepicker', DatePicker);

    //Montar componentes.
    pagosRealizadosAlumnos.mount('#pagosRealizadosAlumnos');

}


//PagoAlquiler
// Importación de componentes padre.
import PagoAlquiler from './components/pagosAlquiler/PagoAlquiler.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#pagoAlquiler') != null) {


    // Comenzar a montar los componentes padres.
    const pagoAlquiler = createApp(PagoAlquiler);

    // Agregar sub-componentes al componente padre (pagosRealizadosAlumnos).
    pagoAlquiler.component('PagoAlquilerCrear', require('./components/pagosAlquiler/PagoAlquilerCrear.vue').default);
    pagoAlquiler.component('PagoAlquilerEditar', require('./components/pagosAlquiler/PagoAlquilerEditar.vue').default);

    //Montar componentes.
    pagoAlquiler.mount('#pagoAlquiler');

}

//PagosRealizados Alquiler
// Importación de componentes padre.
import PagosRealizados from './components/pagosRealizadosAlquiler/PagosRealizados.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#pagosRealizados') != null) {


    // Comenzar a montar los componentes padres.
    const pagosRealizados = createApp(PagosRealizados);

    // Agregar sub-componentes al componente padre (pagosRealizadosAlumnos).
    pagosRealizados.component('PagosRealizadosCrear', require('./components/pagosRealizadosAlquiler/PagosRealizadosCrear.vue').default);
    pagosRealizados.component('PagosRealizadosEditar', require('./components/pagosRealizadosAlquiler/PagosRealizadosEditar.vue').default);
    pagosRealizados.component('datepicker', DatePicker);

    //Montar componentes.
    pagosRealizados.mount('#pagosRealizados');

}


//Diplomas
// Importación de componentes padre.
import Diploma from './components/diplomas/Diploma.vue'

//Condición para cargar el componente si se encuentra el id en la vista y evitar cargar el resto de componentes
if (document.querySelector('#diploma') != null) {


    // Comenzar a montar los componentes padres.
    const diploma = createApp(Diploma);

    // Agregar sub-componentes al componente padre (pagosRealizadosAlumnos).
    diploma.component('DiplomaCrear', require('./components/diplomas/DiplomaCrear.vue').default);
    diploma.component('DiplomaEditar', require('./components/diplomas/DiplomaEditar.vue').default);
    diploma.component('datepicker', DatePicker);

    //Montar componentes.
    diploma.mount('#diploma');

}

