<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para editar el registro-->
  <div class="modal" id="editarDiploma">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="editarDiplomaLabel">Editar diploma</h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <fieldset>
            <legend>Información del alumno</legend>
            <p class="text-danger font-weight-bold">
              Nombre:
              <label for="nombreAlumno" class="text-dark font-weight-bold">{{
                alumno_info.nombre
              }}</label>
            </p>
            <p class="text-danger font-weight-bold">
              Apellidos:
              <label for="apellidosAlumno" class="text-dark font-weight-bold">
                {{ alumno_info.apellidos }}</label
              >
            </p>
            <p for="alumnoDNI" class="text-danger font-weight-bold">
              DNI:
              <label for="dniAlumno" class="text-dark font-weight-bold">
                {{ alumno_info.documento_identidad }}</label
              >
            </p>
          </fieldset>

          <fieldset>
            <legend>Información del curso</legend>
            <p class="text-danger font-weight-bold">
              ID Edición:
              <label for="edicionCurso" class="text-dark font-weight-bold">{{
                curso_info.id_edicion
              }}</label>
            </p>
            <p class="text-danger font-weight-bold">
              ID Curso:
              <label for="idCurso" class="text-dark font-weight-bold">
                {{ curso_info.id_curso }}</label
              >
            </p>
            <p for="nombreCurso" class="text-danger font-weight-bold">
              Nombre Curso:
              <label for="nombreCurso" class="text-dark font-weight-bold">
                {{ curso_info.nombre }}</label
              >
            </p>
            <p for="horasCurso" class="text-danger font-weight-bold">
              Horas:
              <label for="horasCurso" class="text-dark font-weight-bold">
                {{ curso_info.horas }}</label
              >
            </p>
          </fieldset>

          <label for="calificacion" class="text-dark font-weight-bold"
            >Calificación</label
          >

          <select
            id="calificaciones"
            class="form-control text-dark"
            v-model="calificacion"
          >
            <option value="default">Seleccionar calificación</option>
            <option
              v-for="calificacion in listado_calificaciones"
              v-bind:key="calificacion"
              :value="calificacion"
            >
              {{ calificacion }}
            </option>
          </select>

          <label for="archivo_diplomas" class="text-dark font-weight-bold"
            >Archivo del diploma</label
          >

          <select
            id="archivo_diplomas"
            class="form-control text-dark"
            v-model="archivo_diplomas"
          >
            <option value="default">Seleccionar archivo diploma</option>
            <option
              v-for="archivoDiploma in listado_archivo_diplomas"
              v-bind:key="archivoDiploma"
              :value="archivoDiploma"
            >
              {{ archivoDiploma }}
            </option>
          </select>

          <label for="dni" class="text-dark font-weight-bold"
            >Fecha de emisión</label
          >
          <!--Utilización de vue datepicker -->
          <datepicker
            v-model:value="fecha_emision"
            v-on:clear="
              fecha_emision = '';
              validarDatos();
            "
            v-on:close="validarDatos()"
            :editable="true"
            :input-class="validar_datos.fecha_emision.input_class"
            :format="'DD-MM-YYYY'"
            :value-type="'format'"
            :lang="'es.es'"
          ></datepicker>

          <div
            :class="validar_datos.fecha_emision.div_class"
            v-html="validar_datos.fecha_emision.msg"
          ></div>
        </div>
        <div class="d-flex p-3 justify-content-end">
          <!-- v-on:click asigna un evento que al pulsar llame a la funcion editar...-->
          <button
            type="button"
            name="editarDiploma"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="actualizarDiploma()"
          >
            Guardar cambios
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#editarDiploma").modal({
    show: false,
    backdrop: "static",
  });
});

import moment from "moment";

export default {
  //propiedades donde tenemos acceso a las variables del componente padre
  props: ["listado_calificaciones", "listado_archivo_diplomas"],
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      fecha_emision: "",
      calificacion: "",
      archivo_diplomas: "",
      alumno_info: {
        documento_identidad: "",
        nombre: "",
        apellidos: "",
      },
      curso_info: {
        id_edicion: "",
        id_curso: "",
        nombre: "",
        horas: "",
      },
      ediciones_cursos_id: "",
      diploma_id: "",
      editarDiploma: {}, // La información que se enviará a la base de datos actualizada.
      resultado: {
        // Almacenar el resultado de las operaciones entre el cliente - servidor.
        success: "",
        error: [],
        select: {
          fila_editada: 0, // ID del row que ha sido editada para poderla resaltar.
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        fecha_emision: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "form-control",
        },
      },
    };
  },
  methods: {
    // Rellenamos los datos del formulario con el registro que queremos editar visualizandolo con el showForm desde el padre
    showForm(diploma) {
      this.diploma_id = diploma.id;

      this.alumno_info.documento_identidad = diploma.documento_identidad;
      this.alumno_info.nombre = diploma.nombre;
      this.alumno_info.apellidos = diploma.apellidos;

      this.curso_info.id_edicion = diploma.id_edicion;
      this.curso_info.id_curso = diploma.id_curso;
      this.curso_info.nombre = diploma.nombre_curso;
      this.curso_info.horas = diploma.horas;

      this.ediciones_cursos_id = diploma.id_edicion;

      this.calificacion = diploma.calificacion;
      this.archivo_diplomas = diploma.archivo_diplomas;
      this.fecha_emision = diploma.fecha_emision;

      this.validarDatos();

      $("#editarDiploma").modal("show");
    },
    // Enviar información al controlador una vez que la información es válida.
    actualizarDiploma() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        // Crear el objeto editarDiploma para actualizar en la base de datos.

        this.editarDiploma = {
          fecha_emision: this.fecha_emision,
          calificacion: this.calificacion,
          archivo_diplomas: this.archivo_diplomas,
          alumnos_documento_identidad: this.alumno_info.documento_identidad,
          ediciones_cursos_id: this.ediciones_cursos_id,
        };

        let urlEditarDiploma = "diplomas/" + this.diploma_id;
        axios
          .put(urlEditarDiploma, this.editarDiploma)
          .then((response) => {
            this.resultado.select.fila_editada = this.diploma_id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = [];
            }
          })
          .catch((error) => {
            this.resultado.error = error.response.data.mensaje;
            this.resultado.success = "";
          })
          //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
          .finally(() => {
            $("#editarDiploma").modal("hide");
            this.enviarNotificacion();
            this.bloquear_boton = false;
          });
      }
    },
    validarDatos() {
      let datosValidos = false;

      // FECHA EMISIÓN (PUEDE SER NULO)
      if (this.fecha_emision !== null) {
        if (this.fecha_emision.length > 0) {
          if (moment(this.fecha_emision, "DD-MM-YYYY", true).isValid()) {
            this.validar_datos.fecha_emision.valid = true;
            this.validar_datos.fecha_emision.msg =
              "La <strong>Fecha de emisión</strong> es válida.";
            this.validar_datos.fecha_emision.input_class =
              "form-control text-dark is-valid";
            this.validar_datos.fecha_emision.div_class =
              "d-block valid-feedback";
          } else {
            this.validar_datos.fecha_emision.valid = false;
            this.validar_datos.fecha_emision.msg =
              "La <strong>Fecha de fin</strong> no tiene un formato válido.";
            this.validar_datos.fecha_emision.input_class =
              "form-control text-dark is-invalid";
            this.validar_datos.fecha_emision.div_class =
              "d-block invalid-feedback";
          }
        } else {
          this.fecha_emision = "";
          this.validar_datos.fecha_emision.valid = true;
          this.validar_datos.fecha_emision.msg = "";
          this.validar_datos.fecha_emision.input_class = "form-control";
          this.validar_datos.fecha_emision.div_class = "";
        }
      } else {
        this.fecha_emision = "";
        this.validar_datos.fecha_emision.valid = true;
        this.validar_datos.fecha_emision.msg = "";
        this.validar_datos.fecha_emision.input_class = "form-control";
        this.validar_datos.fecha_emision.div_class = "";
      }
      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (this.validar_datos.fecha_emision.valid) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },
  },
};
</script>