<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Listado de Alumnos</h4>
          </div>
          <!--mensajes de alerta al crear eliminar o modificar registros-->
          <div
            class="card-header"
            v-if="success.length > 0 || Object.keys(this.error).length > 0"
          >
            <div class="alert alert-success" v-if="success.length > 0">
              <p class="m-auto">{{ success }}</p>
            </div>

            <div
              class="alert alert-danger"
              v-if="Object.keys(this.error).length > 0"
            >
              <div v-for="mensaje in error" :key="mensaje">
                {{ mensaje[0] }}
              </div>
            </div>
          </div>
          <!--contenido tabla crud-->
          <div class="card-body">
            <div class="table-full-width">
              <table
                class="table display wrap"
                style="width: 100%"
                cellspacing="0"
                id="alumnoDatatable"
              >
                <thead class="text-primary">
                  <tr>
                    <th class="text-center">Documento de Identidad</th>
                    <th class="text-center">Nombre</th>
                    <th class="text-center">Apellidos</th>
                    <th class="text-center">Correo</th>
                    <th class="text-center">Telefono</th>
                    <th class="text-center">Nº Seguridad Social</th>
                    <th class="text-center">Fecha de nacimiento</th>
                    <th class="text-center">Nivel Académico</th>
                    <th class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <!--recorremos el array alumnos que contiene todos los registros que hemos obtenido desde el controlador-->
                  <tr
                    v-for="alumno in alumnos"
                    :id="alumno.documento_identidad"
                    :key="alumno.documento_identidad"
                  >
                    <!--mostramos los apartados de cada registro de la tabla-->
                    <td class="text-center">
                      {{ alumno.documento_identidad }}
                    </td>
                    <td class="text-center">{{ alumno.nombre }}</td>
                    <td class="text-center">{{ alumno.apellidos }}</td>
                    <td class="text-center">{{ alumno.correo }}</td>
                    <td class="text-center">{{ alumno.telefono }}</td>
                    <td class="text-center">{{ alumno.inss }}</td>
                    <td class="text-center">{{ alumno.fecha_nacimiento }}</td>
                    <td class="text-center">{{ alumno.nivel_academico }}</td>
                    <td class="text-center">
                      <button
                        title="editar"
                        type="button"
                        class="btn btn-success btn-sm btn-round btn-icon"
                        v-on:click="$refs.editarAlumno.showForm(alumno)"
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </button>

                      <!--utilizamos la directiva v-on:click para realizar la accion de llamar a la funcion borrar-->
                      <button
                        type="button"
                        title="eliminar"
                        class="btn btn-danger btn-sm btn-round btn-icon"
                        v-on:click="deleteAlumnos(alumno)"
                      >
                        <i class="tim-icons icon-trash-simple"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!--Componentes hijos agregados llamando al metodo mostrarNotificacion que tiene el padre-->
              <AlumnoCrear
                @mostrarNotificacion="mostrarNotificacion"
              ></AlumnoCrear>
              <AlumnoEditar
                @mostrarNotificacion="mostrarNotificacion"
                ref="editarAlumno"
              ></AlumnoEditar>
            </div>
          </div>
        </div>
        <!--mostramos el formulario de crear un curso del componente hijo, CursoCrear-->
        <button
          data-bs-toggle="modal"
          data-bs-target="#crearAlumno"
          type="button"
          class="btn btn-info"
        >
          Agregar Alumno
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  //cuando se carga el componente obtiene los registros desde el controlador
  created: function () {
    this.getAlumnos(); //accedemos al metodo index del controlador que devuelve los regitros de la tabla
  },
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      alumnos: [], //Array que utilizaremos para almacenar los registros que haya creados en la bd.
      success: "", //variable que utilizaremos para almacenar la respuesta satisfactoria del controlador
      error: "",
      select: {
        fila_editada: 0,
      },
    };
  },
  //funcion para definir los diferentes metodos que se utilizaran a lo largo del componente
  methods: {
    //funcion para obtener los cursos que hay en la base de datos
    getAlumnos() {
      // Para poder actualizar correctamente el datatable.
      $("#alumnoDatatable").DataTable().destroy();

      let alumnoIndex = "alumnos"; //almaceno en la variable la ruta que accede al metodo index del controlador

      axios
        .get(alumnoIndex)
        .then((response) => {
          //recoger la respuesta del controlador
          this.alumnos = response.data; //lo almacena en el array alumnos
        })
        .finally(() => {
          $("#alumnoDatatable").DataTable({
            //cambio de idioma datatables a español
            language: {
              url: "datatables-es.txt",

              buttons: {
                selectAll: "Seleccionar Todo",
                selectNone: "Deseleccionar Todo",
              },
            },
            //activamos la funcion select para seleccionar filas
            select: true,

            //datatables responsive
            responsive: true,
            //prioridad de visibilidad en el responsive la columna más a la izquierda ( target:0) se le asigna una prioridad de 1,
            // mientras que a la columna más a la derecha ( target:-1) se le asigna una prioridad de 2.
            columnDefs: [
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 },
            ],

            //cuando la tabla esta cargada se dirige al último registro editado
            initComplete: (settings, json) => {
              if (this.select.fila_editada != -1) {
                $("#alumnoDatatable")
                  .DataTable()
                  .row("tr#" + this.select.fila_editada)
                  .select()
                  .show()
                  .draw(false);
              }
            },

            // Funcionalidades de los botones que se mostrarán en la datatable.
            dom: "QBfrltip",
            destroy: true,
            //configuracion de botones que apareceran como opciones
            buttons: [
              "selectAll",
              "selectNone",
              {
                extend: "copyHtml5",
                exportOptions: {
                  columns: "th:not(:nth-child(8))", // Ocultar la columna 8 (Acciones) al exportar.
                },
              },
              {
                extend: "excelHtml5",
                title: "Tamonante Educa - Alumnos",
                exportOptions: {
                  columns: "th:not(:nth-child(8))", // Ocultar la columna 8 (Acciones) al exportar.
                },
              },
              {
                extend: "pdfHtml5",
                title: "Tamonante Educa - Alumnos",
                exportOptions: {
                  columns: "th:not(:nth-child(8))", // Ocultar la columna 8 (Acciones) al exportar.
                },
              },

              {
                extend: "print",
                title: "Tamonante Educa - Alumnos",
                exportOptions: {
                  columns: "th:not(:nth-child(8))", // Ocultar la columna 8 (Acciones) al exportar.
                },
              },
              {
                // boton para elegir las columnas que se quieren mostrar en la tabla.
                extend: "colvis",
              },
            ],

            // Especificar las columnas disponibles para filtrar.
            searchBuilder: {
              columns: [0, 1, 2, 3, 4, 5, 6],
            },
          });
        });
    },
    //funcion para eliminar un registro de la bd
    deleteAlumnos(alumno) {
      if (
        confirm(
          "Si elimina el alumno, se eliminaran toda la informacion asociada a él. ¿Desea continuar?"
        )
      ) {
        //resetear variables
        this.success = "";
        this.error = "";
        // le pasamos a la funcion el objeto alumno del array v-for
        let alumnoDelete = "alumnos/" + alumno.documento_identidad; //almacenamos en la variable la ruta hacia el controlador + id que accede al metodo delete del controlador
        axios
          .delete(alumnoDelete)
          .then((response) => {
            if (response.status === 200) {
              this.success = response.data.mensaje;
              this.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.error = error.response.data.mensaje;
              this.success = "";
            }
          })

          .finally(() => {
            this.getAlumnos(); // Refrescamos la lista de la tabla con la funcion creada anteriomente.
          });
      }
    },

    //funcion que muestra un mensaje sastisfactorio o de errores de las operaciones que se lleven a cabo  a través dela variable "resultado" en los componentes hijos
    mostrarNotificacion(resultado) {
      this.getAlumnos(); //refresca la tabla
      this.select.fila_editada = resultado.select.fila_editada;

      this.success = resultado.success;
      this.error = resultado.error;
    },
  },
};
</script>