<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el regsitro-->
  <div class="modal" id="crearAlumno">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="crearAlumnoLabel">Crear Alumno</h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <label for="documento_identidad" class="text-dark font-weight-bold"
            >Documento de identidad</label
          >
          <!-- v-model almacenamos en la variable los datos introducidos en el input -->
          <input
            type="text"
            class="form-control text-dark"
            name="documento_identidad"
            v-model="nuevoAlumno.documento_identidad"
            :class="validar_datos.documento_identidad.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.documento_identidad.div_class"
            v-html="validar_datos.documento_identidad.msg"
          ></div>

          <label for="nombre" class="text-dark font-weight-bold"
            >Nombre del Alumno</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="nombre"
            v-model="nuevoAlumno.nombre"
            :class="validar_datos.nombre.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.nombre.div_class"
            v-html="validar_datos.nombre.msg"
          ></div>

          <label for="apellidos" class="text-dark font-weight-bold"
            >Apellidos</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="apellidos"
            v-model="nuevoAlumno.apellidos"
            :class="validar_datos.apellidos.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.apellidos.div_class"
            v-html="validar_datos.apellidos.msg"
          ></div>

          <label for="correo" class="text-dark font-weight-bold">Correo</label>

          <input
            type="text"
            class="form-control text-dark"
            name="correo"
            v-model="nuevoAlumno.correo"
            :class="validar_datos.correo.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.correo.div_class"
            v-html="validar_datos.correo.msg"
          ></div>

          <label for="telefono" class="text-dark font-weight-bold"
            >Telefono</label
          >

          <input
            type="text"
            class="form-control text-dark"
            name="telefono"
            v-model="nuevoAlumno.telefono"
            :class="validar_datos.telefono.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.telefono.div_class"
            v-html="validar_datos.telefono.msg"
          ></div>

          <label for="inss" class="text-dark font-weight-bold"
            >Nº Seguridad Social</label
          >
          <input
            type="text"
            class="form-control text-dark"
            name="inss"
            v-model="nuevoAlumno.inss"
            :class="validar_datos.inss.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.inss.div_class"
            v-html="validar_datos.inss.msg"
          ></div>

          <label for="fecha_nacimiento" class="text-dark font-weight-bold"
            >Fecha de nacimiento</label
          >
          <!--Utilización de vue datepicker -->
          <datepicker
            v-model:value="nuevoAlumno.fecha_nacimiento"
            v-on:clear="
              nuevoAlumno.fecha_nacimiento = '';
              validarDatos();
            "
            v-on:close="validarDatos()"
            :editable="true"
            :input-class="validar_datos.fecha_nacimiento.input_class"
            :format="'DD-MM-YYYY'"
            :value-type="'format'"
            :lang="'es.es'"
          ></datepicker>

          <div
            :class="validar_datos.fecha_nacimiento.div_class"
            v-html="validar_datos.fecha_nacimiento.msg"
          ></div>

          <label for="nivel_academico" class="text-dark font-weight-bold"
            >Nivel Académico</label
          >
          <input
            type="text"
            class="form-control text-dark"
            name="nivel_academico"
            v-model="nuevoAlumno.nivel_academico"
            :class="validar_datos.nivel_academico.input_class"
            v-on:blur="validarDatos()"
          />

          <div
            :class="validar_datos.nivel_academico.div_class"
            v-html="validar_datos.nivel_academico.msg"
          ></div>
        </div>
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="crearAlumno"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearAlumno()"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#crearAlumno").modal({
    show: false,
    backdrop: "static",
  });
});

import moment from "moment"; //importamos la libreria de moment para validar fechas

export default {
  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      nuevoAlumno: {
        documento_identidad: null,
        nombre: null,
        apellidos: null,
        correo: null,
        telefono: null,
        inss: "",
        fecha_nacimiento: "",
        nivel_academico: "",
      },
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        documento_identidad: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        nombre: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        apellidos: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        correo: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        telefono: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        inss: {
          msg: "",
          valid: true, // inicializado en true cuando sean campos no obligatorios.
          div_class: "",
          input_class: "",
        },
        fecha_nacimiento: {
          msg: "",
          valid: true, // inicializado en true cuando sean campos no obligatorios.
          div_class: "",
          input_class: "form-control",
        },
        nivel_academico: {
          msg: "",
          valid: true, // inicializado en true cuando sean campos no obligatorios.
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    //funcion para crear registros en la base de datos
    crearAlumno() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        let alumnoStore = "alumnos"; //almaceno en la variable la ruta que accede al metodo store del controlador
        axios
          .post(alumnoStore, this.nuevoAlumno)
          .then((response) => {
            this.resultado.select.fila_editada = this.nuevoAlumno.documento_identidad;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })
          //cuando todo se haya completado muestra notificacion en el padre
          .finally(() => {
            //se cierra cuando esta toda la infromación correcta y se ha mandado al servidor
            $("#crearAlumno").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      } else {
        this.mostrarErrores();
      }
    },

    // COMIENZAN LAS VALIDACIONES DEL FORMULARIO
    validarDatos() {
      let datosValidos = false; //Devuelve true si los datos estan bien y false si hay algún dato mal.

      // DNI

      if (this.nuevoAlumno.documento_identidad !== null) {
        if (this.nuevoAlumno.documento_identidad.length === 0) {
          this.validar_datos.documento_identidad.valid = false;
          this.validar_datos.documento_identidad.msg =
            "El <strong>Documento de Identidad</strong> es obligatorio.";
          this.validar_datos.documento_identidad.input_class = "is-invalid"; //color para el input
          this.validar_datos.documento_identidad.div_class = "invalid-feedback"; //color para el div
        } else {
          // Comprobar formato
          if (
            /^([a-zA-Z0-9]{9})$/.test(this.nuevoAlumno.documento_identidad) //test, permite comprobar si una cadena de texto cumple con un formato regex determinado
          ) {
            this.validar_datos.documento_identidad.valid = true;
            this.validar_datos.documento_identidad.msg =
              "El <strong>Documento de Identidad</strong> es válido";
            this.validar_datos.documento_identidad.input_class = "is-valid";
            this.validar_datos.documento_identidad.div_class = "valid-feedback";
          } else {
            this.validar_datos.documento_identidad.valid = false;
            this.validar_datos.documento_identidad.msg =
              "El <strong>Documento de Identidad</strong> no tiene un formato válido.";
            this.validar_datos.documento_identidad.input_class = "is-invalid";
            this.validar_datos.documento_identidad.div_class =
              "invalid-feedback";
          }
        }
      }

      // NOMBRE

      // Comprobar si no es nulo para que valide campo por campo
      if (this.nuevoAlumno.nombre !== null) {
        if (this.nuevoAlumno.nombre.length === 0) {
          this.validar_datos.nombre.valid = false;
          this.validar_datos.nombre.msg =
            "El <strong>Nombre del Alumno</strong> es obligatorio.";
          this.validar_datos.nombre.input_class = "is-invalid";
          this.validar_datos.nombre.div_class = "invalid-feedback";
        } else {
          if (
            this.nuevoAlumno.nombre.length > 0 &&
            this.nuevoAlumno.nombre.length <= 45
          ) {
            this.validar_datos.nombre.valid = true;
            this.validar_datos.nombre.msg =
              "El <strong>Nombre del Alumno</strong> es válido.";
            this.validar_datos.nombre.input_class = "is-valid";
            this.validar_datos.nombre.div_class = "valid-feedback";
          } else {
            this.validar_datos.nombre.valid = false;
            this.validar_datos.nombre.msg =
              "El <strong>Nombre del Alumno</strong> no puede superar los 45 caracteres.";
            this.validar_datos.nombre.input_class = "is-invalid";
            this.validar_datos.nombre.div_class = "invalid-feedback";
          }
        }
      }

      // APELLIDOS

      if (this.nuevoAlumno.apellidos !== null) {
        if (this.nuevoAlumno.apellidos.length === 0) {
          this.validar_datos.apellidos.valid = false;
          this.validar_datos.apellidos.msg =
            "Los <strong>Apellidos</strong> son obligatorios.";
          this.validar_datos.apellidos.input_class = "is-invalid";
          this.validar_datos.apellidos.div_class = "invalid-feedback";
        } else {
          if (
            this.nuevoAlumno.apellidos.length > 0 &&
            this.nuevoAlumno.apellidos.length <= 45
          ) {
            this.validar_datos.apellidos.valid = true;
            this.validar_datos.apellidos.msg =
              "Los <strong>Apellidos</strong> son válidos.";
            this.validar_datos.apellidos.input_class = "is-valid";
            this.validar_datos.apellidos.div_class = "valid-feedback";
          } else {
            this.validar_datos.apellidos.valid = false;
            this.validar_datos.apellidos.msg =
              "Los <strong>Apellidos</strong> no puede superar los 45 caracteres.";
            this.validar_datos.apellidos.input_class = "is-invalid";
            this.validar_datos.apellidos.div_class = "invalid-feedback";
          }
        }
      }

      // CORREO.

      if (this.nuevoAlumno.correo !== null) {
        if (this.nuevoAlumno.correo.length === 0) {
          this.validar_datos.correo.valid = false;
          this.validar_datos.correo.msg =
            "El <strong>Correo</strong> es obligatorio.";
          this.validar_datos.correo.input_class = "is-invalid";
          this.validar_datos.correo.div_class = "invalid-feedback";
        } else {
          if (
            this.nuevoAlumno.correo.length > 0 &&
            this.nuevoAlumno.correo.length <= 128
          ) {
            // Comprobar formato de la dirección de correo (regex).

            if (
              /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9]+([.][a-zA-Z0-9]+)*[.][a-zA-Z]{1,5}/.test(
                this.nuevoAlumno.correo
              )
            ) {
              this.validar_datos.correo.valid = true;
              this.validar_datos.correo.msg =
                "El <strong>Correo</strong> es válido.";
              this.validar_datos.correo.input_class = "is-valid";
              this.validar_datos.correo.div_class = "valid-feedback";
            } else {
              this.validar_datos.correo.valid = true;
              this.validar_datos.correo.msg =
                "El <strong>Correo</strong> no tiene un formato válido.";
              this.validar_datos.correo.input_class = "is-invalid";
              this.validar_datos.correo.div_class = "invalid-feedback";
            }
          } else {
            this.validar_datos.correo.valid = false;
            this.validar_datos.correo.msg =
              "El <strong>Correo</strong> no puede superar los 128 caracteres.";
            this.validar_datos.correo.input_class = "is-invalid";
            this.validar_datos.correo.div_class = "invalid-feedback";
          }
        }
      }

      // TELEFONO

      if (this.nuevoAlumno.telefono !== null) {
        if (this.nuevoAlumno.telefono.length === 0) {
          this.validar_datos.telefono.valid = false;
          this.validar_datos.telefono.msg =
            "El <strong>Teléfono</strong> es obligatorio.";
          this.validar_datos.telefono.input_class = "is-invalid";
          this.validar_datos.telefono.div_class = "invalid-feedback";
        } else {
          // Validar longitud

          if (
            this.nuevoAlumno.telefono.length > 0 &&
            this.nuevoAlumno.telefono.length <= 20
          ) {
            // Validar formato regex.

            if (
              /^\+{1,1}[1-9]+\s?\d+$|^\d+\s?\d+$/.test(
                this.nuevoAlumno.telefono
              )
            ) {
              this.validar_datos.telefono.valid = true;
              this.validar_datos.telefono.msg =
                "El <strong>Teléfono</strong> es válido.";
              this.validar_datos.telefono.input_class = "is-valid";
              this.validar_datos.telefono.div_class = "valid-feedback";
            } else {
              this.validar_datos.telefono.valid = false;
              this.validar_datos.telefono.msg =
                "El <strong>Teléfono</strong> no tiene un formato válido.";
              this.validar_datos.telefono.input_class = "is-invalid";
              this.validar_datos.telefono.div_class = "invalid-feedback";
            }
          } else {
            this.validar_datos.telefono.valid = false;
            this.validar_datos.telefono.msg =
              "El <strong>Teléfono</strong> no puede superar los 20 caracteres.";
            this.validar_datos.telefono.input_class = "is-invalid";
            this.validar_datos.telefono.div_class = "invalid-feedback";
          }
        }
      }

      // INSS

      if (this.nuevoAlumno.inss.length > 0) {
        if (/^(\d{12})$/.test(this.nuevoAlumno.inss)) {
          this.validar_datos.inss.valid = true;
          this.validar_datos.inss.msg =
            "El <strong>Nº Seguridad Social</strong> es válido.";
          this.validar_datos.inss.input_class = "is-valid";
          this.validar_datos.inss.div_class = "valid-feedback";
        } else {
          this.validar_datos.inss.valid = false;
          this.validar_datos.inss.msg =
            "El <strong>Nº Seguridad Social</strong> no tiene un formato válido.";
          this.validar_datos.inss.input_class = "is-invalid";
          this.validar_datos.inss.div_class = "invalid-feedback";
        }
      } else {
        // En caso de volver a ser nulo, retirar clases de estilo para validar.
        this.nuevoAlumno.inss = "";
        this.validar_datos.inss.valid = true;
        this.validar_datos.inss.msg = "";
        this.validar_datos.inss.input_class = "";
        this.validar_datos.inss.div_class = "";
      }

      // FECHA DE NACIMIENTO

      if (this.nuevoAlumno.fecha_nacimiento.length > 0) {
        if (
          moment(
            //válida la fecha
            this.nuevoAlumno.fecha_nacimiento,
            "DD-MM-YYYY",
            true
          ).isValid()
        ) {
          this.validar_datos.fecha_nacimiento.valid = true;
          this.validar_datos.fecha_nacimiento.msg =
            "La <strong>Fecha de nacimiento</strong> es válida.";
          this.validar_datos.fecha_nacimiento.input_class =
            "form-control text-dark is-valid";
          this.validar_datos.fecha_nacimiento.div_class =
            "d-block valid-feedback";
        } else {
          this.validar_datos.fecha_nacimiento.valid = false;
          this.validar_datos.fecha_nacimiento.msg =
            "La <strong>Fecha de nacimiento</strong> no es válida.";
          this.validar_datos.fecha_nacimiento.input_class =
            "form-control text-dark is-invalid";
          this.validar_datos.fecha_nacimiento.div_class =
            "d-block invalid-feedback";
        }
      } else {
        // En caso de volver a ser nulo, retirar clases de estilo para validar.
        this.nuevoAlumno.fecha_nacimiento = "";
        this.validar_datos.fecha_nacimiento.valid = true;
        this.validar_datos.fecha_nacimiento.msg = "";
        this.validar_datos.fecha_nacimiento.input_class = "form-control";
        this.validar_datos.fecha_nacimiento.div_class = "";
      }

      // NIVEL ACADÉMICO.

      if (this.nuevoAlumno.nivel_academico.length > 0) {
        if (
          this.nuevoAlumno.nivel_academico.length > 0 &&
          this.nuevoAlumno.nivel_academico.length <= 60
        ) {
          this.validar_datos.nivel_academico.valid = true;
          this.validar_datos.nivel_academico.msg =
            "El <strong>Nivel Académico</strong> es válido.";
          this.validar_datos.nivel_academico.input_class = "is-valid";
          this.validar_datos.nivel_academico.div_class = "valid-feedback";
        } else {
          this.validar_datos.nivel_academico.valid = false;
          this.validar_datos.nivel_academico.msg =
            "El <strong>Nivel Académico</strong> no puede superar los 60 caracteres.";
          this.validar_datos.nivel_academico.input_class = "is-invalid";
          this.validar_datos.nivel_academico.div_class = "invalid-feedback";
        }
      } else {
        // En caso de volver a ser nulo, retirar clases de estilo para validar.
        this.nuevoAlumno.nivel_academico = "";
        this.validar_datos.nivel_academico.valid = true;
        this.validar_datos.nivel_academico.msg = "";
        this.validar_datos.nivel_academico.input_class = "";
        this.validar_datos.nivel_academico.div_class = "";
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.documento_identidad.valid &&
        this.validar_datos.nombre.valid &&
        this.validar_datos.apellidos.valid &&
        this.validar_datos.correo.valid &&
        this.validar_datos.telefono.valid &&
        this.validar_datos.inss.valid &&
        this.validar_datos.fecha_nacimiento.valid &&
        this.validar_datos.nivel_academico.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    /* mostrarErrores() -> Informa al usuario de los campos erróneos cuando trata de enviar los datos al servidor sin 
      haber rellenado previamente todos los campos del formulario. */

    mostrarErrores() {
      //this.success = "";

      // Documento de identidad
      if (this.nuevoAlumno.documento_identidad === null) {
        this.validar_datos.documento_identidad.valid = false;
        this.validar_datos.documento_identidad.msg =
          "El <strong>Documento de Identidad</strong> es obligatorio.";
        this.validar_datos.documento_identidad.input_class = "is-invalid";
        this.validar_datos.documento_identidad.div_class = "invalid-feedback";
      }

      // Nombre

      if (this.nuevoAlumno.nombre === null) {
        this.validar_datos.nombre.valid = false;
        this.validar_datos.nombre.msg =
          "El <strong>Nombre</strong> es obligatorio.";
        this.validar_datos.nombre.input_class = "is-invalid";
        this.validar_datos.nombre.div_class = "invalid-feedback";
      }

      // Apellidos

      if (this.nuevoAlumno.apellidos === null) {
        this.validar_datos.apellidos.valid = false;
        this.validar_datos.apellidos.msg =
          "Los <strong>Apellidos</strong> son obligatorios.";
        this.validar_datos.apellidos.input_class = "is-invalid";
        this.validar_datos.apellidos.div_class = "invalid-feedback";
      }

      // Correo

      if (this.nuevoAlumno.correo === null) {
        this.validar_datos.correo.valid = false;
        this.validar_datos.correo.msg =
          "El <strong>Correo</strong> es obligatorio.";
        this.validar_datos.correo.input_class = "is-invalid";
        this.validar_datos.correo.div_class = "invalid-feedback";
      }

      // Teléfono

      if (this.nuevoAlumno.telefono === null) {
        this.validar_datos.telefono.valid = false;
        this.validar_datos.telefono.msg =
          "El <strong>Teléfono</strong> es obligatorio.";
        this.validar_datos.telefono.input_class = "is-invalid";
        this.validar_datos.telefono.div_class = "invalid-feedback";
      }
    },

    limpiarFormulario() {
      this.nuevoAlumno = {
        documento_identidad: null,
        nombre: null,
        apellidos: null,
        correo: null,
        telefono: null,
        inss: "",
        fecha_nacimiento: "",
        nivel_academico: "",
      };

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.validar_datos = {
        documento_identidad: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        nombre: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        apellidos: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        correo: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        telefono: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        inss: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
        fecha_nacimiento: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "form-control",
        },
        nivel_academico: {
          msg: "",
          valid: true,
          div_class: "",
          input_class: "",
        },
      };
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },
  },
};
</script>