<template>
  <!--modal nos crea una ventana emergente donde introduciremos los datos para crear el resgitro-->
  <div class="modal" id="crearAlumnosCurso">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="crearAlumnosCursoLabel">
            Matricular Alumno
          </h3>
          <!--Boton para cerrar el modal &times; nos crea la X de cierre-->
          <button type="button" class="close" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body border-top border-bottom mt-3">
          <!--  mostramos información del alumno seleccionado -->
          <fieldset v-if="alumno_info !== 'default'">
            <!--condicion para mostrar información solo cuando se seleccione-->
            <legend>Información del alumno</legend>
            <p class="text-danger font-weight-bold">
              Nombre:
              <label for="nombreAlumno" class="text-dark font-weight-bold">{{
                alumno_info.nombre
              }}</label>
            </p>
            <p class="text-danger font-weight-bold">
              Apellidos:
              <label for="apellidosAlumno" class="text-dark font-weight-bold">
                {{ alumno_info.apellidos }}</label
              >
            </p>
            <p for="alumnoDNI" class="text-danger font-weight-bold">
              DNI:
              <label for="dniAlumno" class="text-dark font-weight-bold">
                {{ alumno_info.documento_identidad }}</label
              >
            </p>
          </fieldset>
          <!--Cursos-->
          <label for="curso" class="text-dark font-weight-bold">Curso</label>
          <!--select para mostrar los cursos ya creados-->
          <select
            name="cursos"
            class="form-control text-dark"
            v-model="curso_info"
            :class="validar_datos.curso_info.input_class"
          >
            <option value="default" selected>Seleccionar curso</option>
            <option
              v-for="curso in listado_cursos"
              :key="curso"
              :value="curso.id"
            >
              {{ curso.id }} {{ curso.nombre }}
            </option>
          </select>

          <div
            :class="validar_datos.curso_info.div_class"
            v-html="validar_datos.curso_info.msg"
          ></div>

          <!--alumnos-->
          <label for="alumno" class="text-dark font-weight-bold">Alumno</label>
          <!--select para mostrar los alumnos ya creados-->
          <select
            name="alumnos"
            class="form-control text-dark"
            v-model="alumno_info"
            :class="validar_datos.alumno_info.input_class"
          >
            <option value="default" selected>Seleccionar alumno</option>
            <option
              v-for="alumno in listado_alumnos"
              :key="alumno"
              :value="alumno"
            >
              {{ alumno.documento_identidad }}
            </option>
          </select>

          <div
            :class="validar_datos.alumno_info.div_class"
            v-html="validar_datos.alumno_info.msg"
          ></div>
        </div>
        <!-- v-on:click asigna un evento que al pulsar llame a la funcion crear-->
        <div class="d-flex p-3 justify-content-end">
          <button
            type="button"
            name="crearAlumnosCurso"
            class="btn btn-primary mr-1"
            :disabled="bloquear_boton"
            v-on:mousedown="crearAlumnoCurso()"
          >
            Guardar
          </button>
          <button
            type="button"
            class="btn btn-secondary ml-1"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//JQuery
$(document).ready(function () {
  // Evita que la ventana emergente "modal" se cierre cuando hagamos click fuera de ella.
  $("#crearAlumnosCurso").modal({
    show: false,
    backdrop: "static",
  });
});

export default {
  //propiedades donde tenemos acceso a las variables del componente padre
  props: ["listado_cursos", "listado_alumnos"],

  //funcion para definir los diferentes tipos de variables para utilizarlas a lo largo del componente.
  data: function () {
    return {
      alumno_info: "default", // Guardamos los datos del alumno seleccionado en el select.
      curso_info: "default", // Guardamos SOLO el ID del curso seleccionado en el select.
      nuevoAlumnoCurso: {},
      alumno_curso_id: "",
      resultado: {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      },

      //variable booleana que bloquea o habilita el boton de guardar
      bloquear_boton: false,

      // VALIDACIONES
      validar_datos: {
        alumno_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        curso_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      },
    };
  },
  methods: {
    //funcion para crear registros en la base de datos
    crearAlumnoCurso() {
      if (this.validarDatos()) {
        this.bloquear_boton = true;

        // Almacenar en nuevoAlumnoCurso el id del curso y el dni del alumno.

        this.nuevoAlumnoCurso = {
          alumnos_documento_identidad: this.alumno_info.documento_identidad,
          cursos_id: this.curso_info,
        };

        let alumnoCursoStore = "alumnosPorCursos"; //almaceno en la variable la ruta que accede al metodo store del controlador
        axios
          .post(alumnoCursoStore, this.nuevoAlumnoCurso)
          .then((response) => {
            this.resultado.select.fila_editada = response.data.id;
            // Comprobar el código de la respuesta del controlador.
            if (response.status === 200) {
              this.resultado.success = response.data.mensaje;
              this.resultado.error = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.resultado.error = error.response.data.mensaje;
              this.resultado.success = "";
            }
          })
          //cuando todo se haya completado muestra notificacion en el padre
          .finally(() => {
            $("#crearAlumnosCurso").modal("hide");
            this.enviarNotificacion();
            this.limpiarFormulario();
            this.bloquear_boton = false;
          });
      }
    },

    validarDatos() {
      let datosValidos = false;

      // EDICIONES CURSOS ID (OBLIGATORIO)

      if (this.curso_info === "default") {
        this.validar_datos.curso_info.valid = false;
        this.validar_datos.curso_info.msg =
          "Debe seleccionarse un <strong>Curso</strong>.";
        this.validar_datos.curso_info.input_class = "is-invalid";
        this.validar_datos.curso_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.curso_info.valid = true;
        this.validar_datos.curso_info.msg =
          "El <strong>Curso</strong> es válido.";
        this.validar_datos.curso_info.input_class = "is-valid";
        this.validar_datos.curso_info.div_class = "valid-feedback";
      }

      // ALUMNOS DOCUMENTO IDENTIDAD (OBLIGATORIO)

      if (this.alumno_info === "default") {
        this.validar_datos.alumno_info.valid = false;
        this.validar_datos.alumno_info.msg =
          "Debe seleccionarse un <strong>Alumno</strong>.";
        this.validar_datos.alumno_info.input_class = "is-invalid";
        this.validar_datos.alumno_info.div_class = "invalid-feedback";
      } else {
        this.validar_datos.alumno_info.valid = true;
        this.validar_datos.alumno_info.msg =
          "El <strong>Alumno</strong> es válido.";
        this.validar_datos.alumno_info.input_class = "is-valid";
        this.validar_datos.alumno_info.div_class = "valid-feedback";
      }

      // Comprobar si todos los datos se han validado correctamente, y si no, mostrar los errores (en caso de que no se hayan completado los campos obligatorios del formulario).

      if (
        this.validar_datos.alumno_info.valid &&
        this.validar_datos.curso_info.valid
      ) {
        datosValidos = true;
      } else {
        datosValidos = false;
      }

      return datosValidos;
    },

    //funcion que llama a la funcion mostrarNotificacion del padre y pasandole la variable resultado del hijo
    enviarNotificacion() {
      this.$emit("mostrarNotificacion", this.resultado);
    },

    limpiarFormulario() {
      this.alumno_info = "default";
      this.curso_info = "default";
      this.nuevoAlumnoCurso = {};
      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.resultado = {
        success: "",
        error: "",
        select: {
          fila_editada: 0,
        },
      };

      this.validar_datos = {
        curso_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
        alumno_info: {
          msg: "",
          valid: false,
          div_class: "",
          input_class: "",
        },
      };
    },
  },
};
</script>